import React from 'react';
import PropTypes from 'prop-types';
import { useHttpGetRequest } from 'src/utils/httpClient';
import { useTheme } from '@material-ui/styles';
import WidgetSkeleton from 'src/components/Skeleton';
import moment from 'moment';
import { useWidgetComponentContext } from 'src/components/Widgets/WidgetComponentContext';
import DoughnutChart from 'src/components/Charts/DoughnutChart';

const GroupMessageStatusWidget = ({ settings }) => {
    const theme = useTheme();
    const { shouldRefresh, setShouldRefresh } = useWidgetComponentContext();

    const { sendMessageResponse, groupServiceId, chips: _chips } = settings;

    const { payload: statusResponse, loading: statusLoading, query: _refreshStatus } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/dialog/sendmessage/status/${sendMessageResponse?.id}`
    });

    const { payload: groupContacts, loading: getGroupContactsLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/group/contacts/${groupServiceId}`,
        params: {
            ...sendMessageResponse?.groupContactsFilter ? { $filter: sendMessageResponse?.groupContactsFilter } : {}
        }
    });

    const refreshStatus = React.useCallback(_refreshStatus, []);

    React.useEffect(() => {
        if (shouldRefresh) {
            refreshStatus();
            setShouldRefresh(false);
        }
    }, [shouldRefresh, setShouldRefresh, refreshStatus]);

    const allActiveContacts = React.useMemo(() => groupContacts?.filter((c) => c.active)?.map((c) => c?.phone ?? null)?.filter((phone) => phone !== null) ?? [], [groupContacts]);

    const recipientStatuses = React.useMemo(() => {
        const defaultResponse = { success: {}, error: {} };

        return statusResponse?.output?.reduce((recipients, batch) => {
            if (batch.success) {
                return {
                    ...recipients,
                    success: {
                        ...recipients.success,
                        ...batch?.recipientBatch?.reduce((batchRecipients, batchRecipient) => ({ ...batchRecipients, [batchRecipient.address]: batchRecipient }), {}) ?? {}
                    }
                };
            }
            return {
                ...recipients,
                error: {
                    ...recipients.error,
                    ...batch?.recipientBatch?.reduce((batchRecipients, batchRecipient) => ({ ...batchRecipients, [batchRecipient.address]: batchRecipient }), {}) ?? {}
                }
            };
        }, defaultResponse) ?? defaultResponse;
    }, [statusResponse]);

    const errorCount = Object.keys(recipientStatuses.error)?.length ?? 0;
    const successCount = Object.keys(recipientStatuses.success)?.length ?? 0;
    const scheduledCount = React.useMemo(() => allActiveContacts?.filter((phone) => !recipientStatuses.success[phone] && !recipientStatuses.error[phone])?.length ?? 0, [allActiveContacts, recipientStatuses.success, recipientStatuses.error]);

    const chips = React.useMemo(() => {
        if (statusResponse) {
            const started = moment(statusResponse.createdTime).format('DD.MM.yyyy HH:mm');
            const updated = moment(statusResponse.lastUpdatedTime).format('DD.MM.yyyy HH:mm');

            return [
                ..._chips,
                `Status: ${statusResponse?.runtimeStatus ?? null}`,
                `Started: ${statusResponse.createdTime ? started : null}`,
                `Updated: ${statusResponse.lastUpdatedTime ? updated : null}`
            ].filter((chip) => chip.indexOf('null') === -1);
        }
        return _chips;
    }, [_chips, statusResponse]);

    const data = {
        datasets: [
            {
                data: [errorCount, scheduledCount, successCount],
                backgroundColor: [
                    theme.palette.status.failed.main,
                    theme.palette.status.pending.main,
                    theme.palette.status.delivered.main
                ],
                borderWidth: 1,
                borderColor: theme.palette.common.white,
                hoverBorderColor: theme.palette.common.white
            }
        ],
        labels: ['Failed', 'Pending', 'Sent']
    };

    if ((statusLoading || getGroupContactsLoading) /* && !statusResponse && !groupContacts */) {
        return <WidgetSkeleton />;
    }

    return (
        <DoughnutChart data={data} chips={chips} />
    );
};

GroupMessageStatusWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 3,
        h: 3
    },
    lg: {
        w: 4,
        h: 3
    },
    md: {
        w: 3,
        h: 3
    },
    xs: {
        w: 3,
        h: 3
    },
    xxs: {
        w: 4,
        h: 3
    }
};

export default GroupMessageStatusWidget;