import defaultTheme from '@material-ui/core/styles/defaultTheme';
import palette from '../palette';
import typography from '../typography';
import MuiTypography from './MuiTypography';
import MuiButton from './MuiButton';
import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiChip from './MuiChip';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItem from './MuiListItem';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiToggleButton from './MuiToggleButton';
import MuiDialog from './MuiDialog';
import MuiAlert from './MuiAlert';
import MuiCssBaseline from './MuiCssBaseline';

export default {
    MuiCssBaseline,
    MuiTypography,
    MuiCardActions,
    MuiCardContent,
    MuiCardHeader,
    MuiChip,
    MuiIconButton,
    MuiInputBase,
    MuiLinearProgress,
    MuiListItem,
    MuiListItemIcon,
    MuiOutlinedInput,
    MuiPaper,
    MuiTableCell,
    MuiTableRow,
    MuiToggleButton,
    MuiAlert,
    ...MuiDialog,

    MuiButton: {
        ...MuiButton,
        containedPrimary: {
            '&:hover': {
                backgroundColor: palette.primary.main
            }
        }
    },
    MuiBottomNavigation: {
        root: {
            backgroundColor: palette.primary.main
        }
    },
    MuiBottomNavigationAction: {
        root: {
            color: palette.white,
            '&.Mui-selected': {
                color: palette.white,
                fontWeight: typography.fontWeightBold,

            }
        }
    },
    MuiDivider: {
        root: {
            backgroundColor: palette.background.default
        }
    },
    MuiDrawer: {
        root: {
            '& $paper': {
                border: 0
            }
        }
    },
    MUIDataTable: {
        responsiveBase: {
            overflow: 'auto'
        }
    },
    MUIDataTableToolbarSelect: {
        root: {
            backgroundColor: palette.background.paper
        }
    },
    MUIDataTableSelectCell: {
        headerCell: {
            backgroundColor: palette.background.gray,
            color: palette.white
        }
    },
    MUIDataTableHeadCell: {
        fixedHeader: {
            backgroundColor: palette.background.gray,
            color: palette.white
        },
        sortActive: {
            color: palette.white
        }
    },
    MuiTableHead: {
        root: {
            backgroundColor: palette.background.gray,
            '& *': {
                color: palette.white
            },
            '& span.MuiButtonBase-root': {
                color: palette.white
            }
        }
    },
    MuiTab: {
        wrapper: {
            fontWeight: 600
        }
    },
    MuiBreadcrumbs: {
        li: {
            '& > a, a:visited': {
                color: 'inherit'
            }
        }
    },
    MUIDataTableToolbar: {
        // Enable toolbar custom actions to be ordered with css property "order"
        actions: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        filterPaper: {
            width: '90%',
            maxWidth: '800px'
        }
    },
    MUIDataTableFilter: {
        resetLink: {
            display: 'none'
        }
    },
    MUIDataTableBodyRow: {
        root: {
            '& > td': {
                maxWidth: 200
            }
        }
    },
    MUIDataTableBodyCell: {
        root: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    MUIDataTableFilterList: {
        chip: {
            marginBottom: '10px',
            border: '1px solid #e2e6e9'
        }
    },
    MuiTablePagination: {
        toolbar: {
            [defaultTheme.breakpoints.up('sm')]: {
                display: 'flex'
            }
        }
    },
    MuiToolbar: {
        root: {
            minHeight: '0px !important'
        }
    }
};