import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Typography, Divider, Chip } from '@material-ui/core';

const useStatsStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden'
    },
    statsContainer: {
        display: 'flex',
        maxWidth: '450px',
        margin: '0 auto'
    },
    statsItem: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2, 1, 2),
        '&:not(:last-of-type)': {
            borderRight: `1px solid ${theme.palette.divider}`
        }
    },
    statsItemColor: ({ color }) => ({
        backgroundColor: color,
        height: theme.spacing(2),
        marginBottom: theme.spacing(1)
    })
}));

const DoughnutChartStats = ({ dataset, labels }) => {
    const classes = useStatsStyles();

    const { data, backgroundColor } = dataset;

    return (
        <div className={classes.root}>
            <Divider />
            <div className={classes.statsContainer}>
                {data?.map((value, index) => {
                    const label = labels[index];
                    const color = backgroundColor[index];
                    return <StatsItem key={label} label={label} value={value} color={color} />;
                })}
            </div>
        </div>
    );
};

DoughnutChartStats.propTypes = {
    dataset: PropTypes.object.isRequired,
    labels: PropTypes.array.isRequired
};

const StatsItem = ({ label, value, color }) => {
    const classes = useStatsStyles({ color });

    return (
        <div className={classes.statsItem}>
            <div className={classes.statsItemColor} />
            <Typography
                align="center"
                component="h6"
                gutterBottom
                variant="overline"
            >
                {label}
            </Typography>
            <Typography
                align="center"
                variant="h4"
            >
                {value}
            </Typography>
        </div>
    );
};

StatsItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
};

const useChartStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    chartAreaContainer: {
        minHeight: 0,
        /* padding: 30, */
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        padding: theme.spacing(1, 2, 2, 2)
    },
    chartContainer: {
        maxHeight: 250,
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    statsContainer: {
        margin: '0 auto',
        display: 'flex',
        maxWidth: 450
    }
}));
const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    animation: {
        animateScale: true,
        animateRotate: true
    },
    legend: {
        display: false
    },
    layout: {
        padding: 0
    },
    tooltips: {
        enabled: false
    }
};

const DoughnutChart = ({ data, chips }) => {
    const classes = useChartStyles();

    return (
        <div className={classes.root}>
            <div className={classes.chipContainer}>
                {chips?.map((c) => <Chip key={c} className={classes.chip} label={c} />)}
            </div>
            <div className={classes.chartAreaContainer}>
                <div className={classes.chartContainer}>
                    <Doughnut
                        data={data}
                        options={options}
                    />
                </div>
            </div>
            <div className={classes.statsContainer}>
                <DoughnutChartStats dataset={data?.datasets?.[0]} labels={data?.labels ?? []} />
            </div>
        </div>
    );
};

DoughnutChart.propTypes = {
    data: PropTypes.shape({
        datasets: PropTypes.array.isRequired,
        labels: PropTypes.array.isRequired
    }).isRequired,
    chips: PropTypes.array
};

export default DoughnutChart;