/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import BarChart from 'src/components/Code/Statistics/BarChart';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        /* minHeight: '500px', */
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(1),
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        padding: 20
    },
    chip: {
        margin: theme.spacing(1),
        border: '1px solid #e2e6e9'
    }
}));

const useOutboundMessageBarData = (statistics, statusFilter, groupBy, visualization, interval) => {
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        let mounted = true;

        // remap codes for bar chart
        const barCodeEvents = statistics?.reduce((events, currentCode) => {
            return [...events, ...currentCode?.events ?? []];
        }, []);

        const resp = barCodeEvents?.reduce((acc, current) => {
            const parsedDate = moment(current?.timestamp);

            const sentAmount = current?.type === 'Sent' && parsedDate?.isBetween(moment(interval.from), moment(interval.to).endOf('date')) ? 1 : 0;
            const redeemedAmount = current?.type === 'Redeemed' && parsedDate?.isBetween(moment(interval.from), moment(interval.to).endOf('date')) ? 1 : 0;
            const voidedAmount = current?.type === 'Voided' && parsedDate?.isBetween(moment(interval.from), moment(interval.to).endOf('date')) ? 1 : 0;
            const recycledAmount = current?.type === 'Recycled' && parsedDate?.isBetween(moment(interval.from), moment(interval.to).endOf('date')) ? 1 : 0;

            const _key = parsedDate.startOf(groupBy).isoWeekday(1).toJSON();

            return {
                ...acc,
                [_key]: {
                    Sent: (acc?.[_key]?.Sent ?? 0) + sentAmount,
                    Redeemed: (acc?.[_key]?.Redeemed ?? 0) + redeemedAmount,
                    Voided: (acc?.[_key]?.Voided ?? 0) + voidedAmount,
                    Recycled: (acc?.[_key]?.Recycled ?? 0) + recycledAmount,
                    Total: (acc?.[_key]?.Total ?? 0) + sentAmount + redeemedAmount + voidedAmount
                }
            };
        }, {});

        const getProperty = (propName) => {
            const formatData = (data, total) => {
                switch (visualization) {
                    case 'percentage': {
                        return (data / total) * 100;
                    }
                    default: {
                        return data;
                    }
                }
            };

            return Object.keys(resp ?? {}).map((key) => ({ x: key, y: formatData(resp?.[key]?.[propName], resp?.[key]?.Total) }));
        };

        if (mounted) {
            switch (statusFilter) {
                case 'sent':
                    setData({
                        Sent: getProperty('Sent')
                    });
                    break;
                case 'redeemed':
                    setData({
                        Redeemed: getProperty('Redeemed')
                    });
                    break;
                case 'voided':
                    setData({
                        Voided: getProperty('Voided')
                    });
                    break;
                case 'recycled':
                    setData({
                        Recycled: getProperty('Recycled')
                    });
                    break;
                default:
                    setData({
                        Sent: getProperty('Sent'),
                        Redeemed: getProperty('Redeemed'),
                        Voided: getProperty('Voided'),
                        Recycled: getProperty('Recycled')

                    });
            }
        }
        return () => { mounted = false; };
    }, [statistics, statusFilter, groupBy, visualization, interval]);

    return data;
};

function CodeStatisticsBar({
    data, chips, groupBy, showBy, statusFilter, interval
}) {
    const classes = useStyles();
    const barData = useOutboundMessageBarData(data, statusFilter, groupBy, showBy, interval);

    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                {chips?.map((c, index) => <Chip key={index} className={classes.chip} label={c} />)}
            </div>
            <div className={classes.chartContainer}>
                <BarChart
                    data={barData}
                    showBy={showBy}
                    groupBy={groupBy}
                />
            </div>
        </Card>
    );
}

CodeStatisticsBar.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    groupBy: PropTypes.string.isRequired,
    showBy: PropTypes.string,
    statusFilter: PropTypes.string.isRequired,
    interval: PropTypes.any
};

export default CodeStatisticsBar;