export default function parseCsv(file) {
    if (file && file.name && file.name?.toLowerCase?.()?.endsWith?.('.csv')) {
        try {
            const validatedFile = new File([file], file.name, { type: 'text/csv' });
            return validatedFile;
        } catch (error) {
            return null;
        }
    }
    return null;
}