import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: '20px'
    },
    header: {
        marginBottom: theme.spacing(3)
    },
    list: {
        marginLeft: theme.spacing(3)
    }
}));

const GeneralInfo = ({ open, setOpen }) => {
    const classes = useStyles();

    const handleCancelClick = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth aria-labelledby="info-template-title">
            <DialogTitle id="info-template-title">General SmartAgent info</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h3" className={classes.header}>
                    How to use SmartAgent.
                </Typography>
                <Typography variant="h5">
                    <ul className={classes.list}>
                        <li>SmartAgent only works for Finnish numbers.</li>
                        <li>Enter a phone number or a name in the 'Phone number or Name' field and click the search button. The contact search is free of charge.</li>
                        <li>If you want to narrow your search you can enter more search criterias in the address, city and free text fields.</li>
                        <li>If you want more details about a contact you can click on it in the contact list. This request is billable and will cost 0,10 EUR.</li>
                        <li>By clicking the 'Send' button in the right hand panel, you will be redirected to the Send Messages service.</li>
                    </ul>
                </Typography>
            </DialogContent>
            <DialogActions>
                <IconButton aria-label="close" onClick={handleCancelClick}>
                    <Tooltip title="Close">
                        <CancelIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

GeneralInfo.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default GeneralInfo;