/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useReportingStatisticsBarData } from 'src/components/OneWayMessaging/OneWayServiceStatistics/hooks';
import InfoChip from 'src/components/InfoChip';
import BarChart from 'src/components/Charts/BarChart';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        overflow: 'hidden',
        padding: theme.spacing(1, 2, 2, 2)
    },
    statsContainer: {
        textAlign: 'center',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const barThickness = 25;
const maxBarThickness = 10;

function OneWayReportingStatisticsDoughnut({
    data, chips, showBy, groupBy
}) {
    const classes = useStyles();
    const theme = useTheme();
    const chartData = useReportingStatisticsBarData(data);

    const datasets = [
        {
            label: chartData.billableMessages ? `Billable Messages - ${chartData.billableMessages?.reduce((total, msg) => total + (msg?.y ?? 0), 0)}` : 'Billable Messages',
            tooltipLabel: 'Billable Messages',
            backgroundColor: theme.palette.status.delivered.main,
            data: chartData?.billableMessages ?? [],
            barThickness,
            maxBarThickness,
            barPercentage: 0.5,
            categoryPercentage: 0.5
        }
    ];
    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                <InfoChip className={classes.chip} title="Billable Messages Info" content="This report is showing the amount of billable messages. More detailed information is presented in Invoicing Reports." />
                {chips?.map((c, index) => <Chip key={index} className={classes.chip} label={c} />)}
            </div>
            <div className={classes.chartContainer}>
                <BarChart data={{ datasets }} showBy={showBy} groupBy={groupBy} />
            </div>
        </Card>
    );
}

OneWayReportingStatisticsDoughnut.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    showBy: PropTypes.string,
    groupBy: PropTypes.string
};

export default OneWayReportingStatisticsDoughnut;