/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Chip
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import moment from 'moment';
import DoughnutChart from 'src/components/OneWayMessaging/OneWayServiceStatistics/Charts/DoughnutChart';
import DoughnutChartStats from 'src/components/OneWayMessaging/OneWayServiceStatistics/Charts/DoughnutChartStats';
import uuid from 'uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        overflow: 'hidden',
        padding: theme.spacing(1, 2, 2, 2)
    },
    statsContainer: {
        textAlign: 'center',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: '1px solid #e2e6e9'
    }
}));

function CarrierBillingStatisticsDoughnut({
    data, chips, showBy, interval
}) {
    const classes = useStyles();
    const theme = useTheme();

    const chartData = React.useMemo(() => {
        const _committed = data?.filter((t) => t?.status === 'Committed' && moment(t?.updated)?.isBetween(moment(interval.from), moment(interval.to).endOf('date'))) ?? [];
        const _cancelled = data?.filter((t) => t?.status === 'Cancelled' && moment(t?.updated)?.isBetween(moment(interval.from), moment(interval.to).endOf('date'))) ?? [];
        const _reserved = data?.filter((t) => t?.status === 'Reserved' && moment(t?.updated)?.isBetween(moment(interval.from), moment(interval.to).endOf('date'))) ?? [];
        const _created = data?.filter((t) => t?.status === 'Created' && moment(t?.updated)?.isBetween(moment(interval.from), moment(interval.to).endOf('date'))) ?? [];
        const _failed = data?.filter((t) => t?.status === 'Failed' && moment(t?.updated)?.isBetween(moment(interval.from), moment(interval.to).endOf('date'))) ?? [];

        const total = _committed.length + _cancelled.length + _reserved.length + _created.length + _failed.length;

        let chartData = [
            {
                id: uuid(),
                label: 'Created',
                value: _created.length,
                cents: _created?.reduce((a, b) => +a + +b.sum, 0),
                color: theme.palette.status.created.main
            },
            {
                id: uuid(),
                label: 'Committed',
                value: _committed.length,
                cents: _committed?.reduce((a, b) => +a + +b.sum, 0),
                color: theme.palette.status.committed.main
            },
            {
                id: uuid(),
                label: 'Reserved',
                value: _reserved.length,
                cents: _reserved?.reduce((a, b) => +a + +b.sum, 0),
                color: theme.palette.status.reserved.main
            },
            {
                id: uuid(),
                label: 'Cancelled ',
                value: _cancelled.length,
                cents: _cancelled?.reduce((a, b) => +a + +b.sum, 0),
                color: theme.palette.status.cancelled.main
            },
            {
                id: uuid(),
                label: 'Failed',
                value: _failed.length,
                cents: _failed?.reduce((a, b) => +a + +b.sum, 0),
                color: theme.palette.status.failed.main
            }
        ];

        if (showBy === 'percentage') {
            chartData = chartData.map((d) => ({ ...d, value: (d.value / total) * 100 }));
        }

        if (showBy === 'euro') {
            chartData = chartData.map((d) => ({ ...d, value: (d.cents / 100) }));
        }

        return chartData;
    }, [data, interval.from, interval.to, showBy, theme.palette.status.cancelled.main, theme.palette.status.committed.main, theme.palette.status.created.main, theme.palette.status.failed.main, theme.palette.status.reserved.main]);

    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                {chips?.map((c, index) => <Chip key={index} className={classes.chip} label={c} />)}
            </div>
            <div className={classes.chartContainer}>
                <DoughnutChart
                    data={chartData}
                    showBy={showBy}
                />
            </div>
            <div className={classes.statsContainer}>
                <DoughnutChartStats
                    data={chartData}
                    showBy={showBy}
                />
            </div>
        </Card>
    );
}

CarrierBillingStatisticsDoughnut.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    showBy: PropTypes.string,
    interval: PropTypes.any
};

export default CarrierBillingStatisticsDoughnut;