import React from 'react';
import PropTypes from 'prop-types';
import WidgetDataProvider from 'src/components/Widgets/WidgetDataProvider';
import { getIntervalDates } from 'src/widgets/widgetUtils';
import { MessageStatsBarChart } from 'src/components/Dialogs/DialogServiceStatistics/DialogMessageStatisticsChart';

const DialogMessageStatisticsWidget = ({ settings }) => {
    const { chips, interval, groupBy } = settings;
    const { from, to, labels } = getIntervalDates(interval);

    return (
        <WidgetDataProvider settings={{
            ...settings,
            requestSettings: {
                ...settings.requestSettings,
                params: {
                    ...settings.requestSettings.params,
                    from: from.valueOf(),
                    to: to.valueOf()
                }
            }
        }}
        >
            {(data) => (
                <MessageStatsBarChart
                    data={Array.isArray(data) ? data : []}
                    groupBy={groupBy}
                    chips={chips?.concat(labels) ?? []}
                />
            )}
        </WidgetDataProvider>
    );
};

DialogMessageStatisticsWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 5,
        h: 3
    },
    lg: {
        w: 6,
        h: 3
    },
    md: {
        w: 6,
        h: 3
    },
    xs: {
        w: 3,
        h: 3
    },
    xxs: {
        w: 8,
        h: 3
    }
};

export default DialogMessageStatisticsWidget;