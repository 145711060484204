import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    /* chartContainer: {
        minHeight: 0,
        display: 'flex',
        flex: 1,
        minWidth: 0,
        maxWidth: '100%',
        position: 'relative',
        height: '100%'
    } */

    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    chartAreaContainer: {
        minHeight: 0,
        /* padding: 30, */
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        padding: theme.spacing(1, 2, 2, 2)
    },
    chartContainer: {
        /* maxHeight: 250,
        height: '100%',
        width: '100%',
        position: 'relative' */
        minHeight: 0,
        display: 'flex',
        flex: 1,
        minWidth: 0,
        maxWidth: '100%',
        position: 'relative',
        height: '100%'
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}));

function BarChart({ data, showBy, groupBy, chips }) {
    const classes = useStyles();
    const theme = useTheme();
    const stacked = false;
    const cornerRadius = 3;
    let yMax;
    if (showBy === 'percentage') {
        yMax = 100;
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cornerRadius,
        animation: {
            duration: 500,
            easing: 'linear'
        },
        legend: {
            display: true
        },
        layout: {
            padding: 0
        },
        scales: {
            xAxes: [
                {
                    offset: true,
                    type: 'time',
                    distribution: 'series',
                    time: {
                        unit: groupBy,
                        displayFormats: {
                            minute: 'mm',
                            hour: 'HH',
                            day: 'DD.MM.YYYY',
                            week: '[w]-w',
                            month: 'MMM-YY',
                            quarter: '[Q]Q-YY',
                            year: 'YYYY',
                        }
                    },
                    bounds: 'ticks',
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        source: 'auto',
                        padding: 20,
                        fontColor: theme.palette.text.secondary
                    },
                    stacked
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    },
                    ticks: {
                        padding: 20,
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                        max: yMax,
                        maxTicksLimit: 5
                    },
                    stacked
                }
            ]
        },
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.common.white,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary,
            callbacks: {
                title: () => { },
                label: (tooltipItem) => {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    if (dataset.tooltipLabel) {
                        return `${data?.datasets[tooltipItem.datasetIndex]?.tooltipLabel}: ${showBy === 'percentage' ? tooltipItem.yLabel?.toFixed(1) : tooltipItem.yLabel}`;
                    }
                    return `${data?.datasets[tooltipItem.datasetIndex]?.label}: ${showBy === 'percentage' ? tooltipItem.yLabel?.toFixed(1) : tooltipItem.yLabel}`;
                }
            }
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.chipContainer}>
                {chips?.map((c) => <Chip key={c} className={classes.chip} label={c} />)}
            </div>
            <div className={classes.chartAreaContainer}>
                <div className={classes.chartContainer}>
                    <Bar
                        data={data}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}

BarChart.propTypes = {
    data: PropTypes.shape({
        datasets: PropTypes.array.isRequired
    }).isRequired,
    showBy: PropTypes.string,
    groupBy: PropTypes.string,
    chips: PropTypes.array
};

export default BarChart;