import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, IconButton, Tooltip, LinearProgress, Input, colors } from '@material-ui/core';
import { useSnackbar } from 'src/components/Snackbar';
import { useHttpRequest } from 'src/utils/httpClient';
import InputAdornment from '@material-ui/core/InputAdornment';
import GeneralInfo from 'src/components/SmartAgent/GeneralInfo';
import SearchResults from 'src/components/SmartAgent/SearchResults';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        flex: 1,
        backgroundColor: '#254a8e'
    },
    logoImg: {
        maxWidth: '50%'
    },
    mainSelect: {
        width: '100%',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        border: 0,
        color: '#333',
        backgroundColor: '#fff',
        borderRadius: '10px',
        fontSize: '1.4em',
        fontWeight: 'bold'
    },
    subSelect: {
        width: '95%',
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        border: 0,
        color: '#333',
        backgroundColor: '#fff',
        borderRadius: '10px'
    },
    button: {
        marginTop: theme.spacing(3),
        width: '100%',
        color: '#fff',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        fontSize: '1.4em',
        fontWeight: 'bold',
        borderRadius: '10px'
    },
    info: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        color: '#fff'
    },
    progrssbar: {
        bottom: '5%',
        position: 'absolute',
        left: '5%',
        width: '90%'
    }
}));

const defaultSearchCriterias = {
    phone: '',
    address: '',
    city: '',
    freeText: ''
};

function SmartAgent() {
    const classes = useStyles();
    const { error: errorSnack } = useSnackbar();
    const [searchCriterias, setSearchCriterias] = React.useState(defaultSearchCriterias);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [resultsOpen, setResultsOpen] = React.useState(false);
    const [searchResults, setSearchresults] = React.useState([]);

    const onChange = (e) => {
        const newData = {
            ...searchCriterias,
            [e.target.name]: e.target.value
        };
        setSearchCriterias(newData);
    };

    const handleInfoOpen = () => {
        setInfoOpen(true);
    };

    const handleInfoClose = () => {
        setInfoOpen(false);
    };

    const handleResultsClose = () => {
        setResultsOpen(false);
    };

    const { mutate: searchContactsRequest, loading: searchContactsLoading } = useHttpRequest((term) => ({
        method: 'GET',
        endpoint: '/smartagent/contacts',
        params: {
            term
        }
    }));

    const handleSearchClick = async () => {
        let term = searchCriterias.phone;
        if (searchCriterias.address) {
            term = term.concat(' ', searchCriterias.address);
        }
        if (searchCriterias.city) {
            term = term.concat(' ', searchCriterias.city);
        }
        if (searchCriterias.freeText) {
            term = term.concat(' ', searchCriterias.freeText);
        }

        const { payload, error, errorMessages } = await searchContactsRequest(term.trim());

        if (!error) {
            setResultsOpen(true);
            setSearchresults(Array.isArray(payload) ? payload : []);
        } else {
            errorSnack(errorMessages);
        }
    };

    const handleClearClick = (criteria) => {
        setSearchCriterias({ ...searchCriterias, [criteria]: '' });
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const endAdornment = (criteria) => {
        if (searchCriterias[criteria]) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => handleClearClick(criteria)}
                        onMouseDown={handleMouseDown}
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            );
        }

        return '';
    };

    return (
        <Card className={classes.card} dir="ltr">
            <CardContent>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <img
                            alt="Logo"
                            src="/images/logos/smartagent_logo.png"
                            className={classes.logoImg}
                        />
                        <IconButton aria-label="info" onClick={handleInfoOpen} className={classes.info}>
                            <Tooltip title="Info">
                                <InfoIcon />
                            </Tooltip>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Input
                            name="phone"
                            onChange={onChange}
                            value={searchCriterias?.phone}
                            className={classes.mainSelect}
                            placeholder="Phone number or Name"
                            endAdornment={endAdornment('phone')}
                            disableUnderline
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} container direction="row">
                        <Grid item xs={4} lg={4}>
                            <Input
                                name="address"
                                onChange={onChange}
                                value={searchCriterias?.address}
                                className={classes.subSelect}
                                placeholder="Address"
                                endAdornment={endAdornment('address')}
                                disableUnderline
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <Input
                                name="city"
                                onChange={onChange}
                                value={searchCriterias?.city}
                                className={classes.subSelect}
                                placeholder="City"
                                endAdornment={endAdornment('city')}
                                disableUnderline
                                style={{ marginLeft: '2.5%' }}
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <Input
                                name="freeText"
                                onChange={onChange}
                                value={searchCriterias?.freeText}
                                className={classes.subSelect}
                                placeholder="Free text"
                                endAdornment={endAdornment('freeText')}
                                disableUnderline
                                style={{ float: 'right' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Button variant="contained" className={classes.button} onClick={handleSearchClick}>
                            SEARCH
                        </Button>
                    </Grid>
                </Grid>
                <GeneralInfo open={infoOpen} setOpen={handleInfoClose} />
                <SearchResults open={resultsOpen} setOpen={handleResultsClose} searchResults={searchResults ?? []} />
                {searchContactsLoading && <LinearProgress className={classes.progrssbar} />}
            </CardContent>
        </Card>
    );
}

export default SmartAgent;