import React from 'react';
import { useHttpGetRequest } from 'src/utils/httpClient';
import PropTypes from 'prop-types';
import WidgetSkeleton from 'src/components/Skeleton';

const WidgetDataContext = React.createContext();
export const WidgetDataConsumer = WidgetDataContext.Consumer;

const WidgetDataProvider = ({ children, settings: { requestSettings } }) => {
    const { payload: data, loading } = useHttpGetRequest(requestSettings);

    if (loading || !data) {
        return <WidgetSkeleton />;
    }

    return children(data);
};

export const useWidgetData = () => {
    const context = React.useContext(WidgetDataContext);
    if (context === undefined) {
        throw new Error('useWidgetData must be used within a WidgetDataProvider');
    }
    return context;
};

WidgetDataProvider.propTypes = {
    children: PropTypes.func,
    settings: PropTypes.object.isRequired
};

export default WidgetDataProvider;