import React, { Suspense } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { LinearProgress, Button, Select, MenuItem, InputLabel, Switch, FormControlLabel, Tooltip, IconButton } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { useHttpGetRequest, useHttpRequest } from 'src/utils/httpClient';
import { useParams } from 'react-router';
import { useSnackbar } from 'src/components/Snackbar';
import PropTypes from 'prop-types';
import SendIcon from '@material-ui/icons/Send';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import ShareIcon from '@material-ui/icons/ReplyRounded';
import Card from '@material-ui/core/Card';
import MUIDataTable from 'mui-datatables';
import PopupDialog from 'src/components/TwoWayMessaging/PopupDialog';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import 'moment/locale/en-gb';
import AttachmentIcon from '@material-ui/icons/AttachmentRounded';
import ImageIcon from '@material-ui/icons/ImageRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrowRounded';
import VisibilityIcon from '@material-ui/icons/VisibilityRounded';
import FallbackImage from 'src/components/FallbackImage';
import SearchResults from 'src/components/SmartAgent/SearchResults';
import CopyableText from 'src/components/CopyableText';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setOtherSettings, setRecipients, clearSendMessageSettings, setSender } from 'src/actions';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { SMARTAGENTSERVICE, DIALOGSERVICE } from 'src/smartDialogProducts';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { size as layout } from 'src/widgets/TwoWayMessagingEventsWidget';
import ExportToolbar from 'src/components/MessageEvents/ExportToolbar';
import FoldersToolbar from 'src/components/MessageEvents/FoldersToolbar';
import ShareLinkPopup from 'src/components/TwoWayMessaging/ShareLinkPopup';
import { sortByProperty } from 'src/utils/arrayFunctions';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { toVatPercentage } from 'src/utils/vatConvert';

export const attachmentsBlobContainer = 'https://arenamsgattachments.blob.core.windows.net/attachments/';

const getStorageKey = (customerId) => `SmartDialog_${window.location.host}_${customerId}_TwowayMessageEvents`;
const getFiltersStorageKey = (serviceId) => `SmartDialog_${window.location.host}_${serviceId}_TwowayMessageEventsFilters`;

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(2)
    },
    mediaThumbnailCell: {
        [theme.breakpoints.up('sm')]: {
            padding: 0
        }
    },
    mediaThumbnailContainer: {
        display: 'flex'
    },
    mediaThumbnail: {
        maxHeight: 45,
        maxWidth: 70,

        [theme.breakpoints.down('sm')]: {
            display: 'block',
            maxHeight: 20,
            maxWidth: 30
        }
    }
}));

const dateFormatter = (rowData) => {
    const date = moment.utc(rowData).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
};

const FILTERINDEXES = {
    id: 0,
    type: 1,
    senderAddress: 2,
    recipients: 3,
    reply: 4,
    created: 5,
    sent: 6,
    direction: 7,
    charge: 8,
    valueTax: 9,
    content: 10,
    deliveryStatus: 11,
    folder: 12,
    attachments: 13,
    lookup: 14
};

const defaultWidgetDisplayValues = [
    {
        column: 'Channel Type',
        display: true
    },
    {
        column: 'Sender',
        display: true
    },
    {
        column: 'Recipient',
        display: true
    },
    {
        column: 'Reply',
        display: true
    },
    {
        column: 'Created',
        display: true
    },
    {
        column: 'Sent',
        display: true
    },
    {
        column: 'Direction',
        display: true
    },
    {
        column: 'Price',
        display: true
    },
    {
        column: 'VAT',
        display: true
    },
    {
        column: 'Content',
        display: true
    },
    {
        column: 'Delivery status',
        display: true
    },
    {
        column: 'Media',
        display: true
    },
    {
        column: 'SmartAgent.',
        display: true
    }
];

const getFilterListDefaultValues = ({ isWidget, hasFoldersEnabled, fromDate, folderId, serviceId, reset }) => {
    // check local storage for filters
    if (serviceId && reset) {
        const storageKey = getFiltersStorageKey(serviceId);
        localStorage.removeItem(storageKey);
    } else if (serviceId && !folderId && !fromDate) {
        // from query string (e.g bookmark)
        const storageKey = getFiltersStorageKey(serviceId);
        const persistedFilters = JSON.parse(localStorage.getItem(storageKey)) ?? [];

        if (persistedFilters?.length > 0) {
            return persistedFilters;
        }
    }
    return [[], [], [], [], [], [fromDate || (moment().add(-1, isWidget ? 'months' : 'days'))], [], [], [], [], [], [], [folderId || (hasFoldersEnabled ? '_inbox_' : '_allmessages_')]];
};

const setWidgetFilterValues = (filters) => {
    // Created from in widget is forced to 1 month back
    // Created to is empty

    const newFilters = filters.map((f, index) => {
        if (index === 5) {
            return [moment().add(-1, 'months')];
        }
        if (index === 6) {
            return [];
        }
        return f;
    });

    return newFilters;
};

export const getThumbnailFallback = (attachment) => {
    if (attachment?.mimeType?.indexOf('image') > -1) {
        return <ImageIcon />;
    }

    if (attachment?.mimeType?.indexOf('video') > -1) {
        return <PlayArrowIcon />;
    }

    return <AttachmentIcon />;
};

const getPersistedColumns = (columns, customerId) => {
    const storageKey = getStorageKey(customerId);
    const { columns: persistedColumns } = JSON.parse(localStorage.getItem(storageKey)) ?? { columns: [] };

    if (persistedColumns.length === 0) {
        return persistedColumns;
    }

    // Check if new column has been added, if so delete stored state
    if (persistedColumns.length === columns.length && persistedColumns.every((col, index) => columns?.[index]?.name === col?.name)) {
        return persistedColumns;
    }

    localStorage.removeItem(storageKey);
    return [];
};

const useColumns = ({ data, serviceHasBilling, filters, setFilters, handleLookupClick, isWidget, widgetColumns, folders, hasFoldersEnabled }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { customerId, serviceId } = useParams();
    const { services } = useSelector((state) => state.twowaymessaging);
    const smartAgentAssigned = useCheckProductAssignment(SMARTAGENTSERVICE);
    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    const chipRemoved = (filterArray, b, index) => {
        const newFilters = [...filterArray];
        const defaultValues = getFilterListDefaultValues({ isWidget, hasFoldersEnabled });
        newFilters.splice(index, 1, defaultValues?.[index] ?? []);
        setFilters(newFilters);
        const storageKey = getFiltersStorageKey(serviceId);
        localStorage.setItem(storageKey, JSON.stringify(newFilters));
        return newFilters;
    };

    const handleSendMessageParams = (dataIndex) => {
        dispatch(clearSendMessageSettings());

        const serviceName = services.find((s) => s.id === serviceId)?.name;
        dispatch(setOtherSettings({ parentMessageId: data?.[dataIndex]?.id, serviceId, parentServiceName: serviceName ?? 'TwoWay Service' }));
        dispatch(setRecipients({ recipients: data?.[dataIndex]?.senderAddress ? [data[dataIndex].senderAddress] : [] }));
        dispatch(setSender({ senderSource: data?.[dataIndex]?.recipients?.[0]?.address ?? '' }));

        history.push(`/${customerId}/sendmessages`);
    };

    const senderFormatter = (dataIndex) => {
        if (data?.[dataIndex]?.direction === 'Outbound') {
            return data?.[dataIndex]?.senderAddress;
        }

        return (
            <div>
                <CopyableText text={data?.[dataIndex]?.senderAddress ?? ''}>{data?.[dataIndex]?.senderAddress ?? ''}</CopyableText>
            </div>
        );
    };

    const recipientFormatter = (dataIndex) => {
        if (data?.[dataIndex]?.direction === 'Inbound') {
            return data?.[dataIndex]?.recipients?.[0]?.address;
        }

        return (
            <div>
                <CopyableText text={data?.[dataIndex]?.recipients?.[0]?.address ?? ''}>{data?.[dataIndex]?.recipients?.[0]?.address ?? ''}</CopyableText>
            </div>
        );
    };

    const replyFormatter = (dataIndex) => {
        if (data?.[dataIndex]?.direction === 'Outbound'
            || data?.[dataIndex]?.type === 'Messenger'
            || data?.[dataIndex]?.type === 'Instagram'
            || data?.[dataIndex]?.type === 'WeChat') {
            return '';
        }

        return (
            <IconButton aria-label="reply" onClick={() => handleSendMessageParams(dataIndex)}>
                <Tooltip title="Send a reply">
                    <SendIcon />
                </Tooltip>
            </IconButton>
        );
    };

    const folderFormatter = ({ id }) => {
        if (id === '_inbox_') {
            return 'Inbox';
        }
        if (id === '_allmessages_') {
            return 'All messages';
        }
        return folders?.find?.((f) => f.id === id)?.name ?? '';
    };

    const smartAgentFormatter = (dataIndex) => {
        if (data?.[dataIndex]?.direction === 'Inbound'
            && data?.[dataIndex]?.type !== 'Messenger'
            && data?.[dataIndex]?.type !== 'Instagram'
            && data?.[dataIndex]?.type !== 'WeChat') {
            return (
                <IconButton aria-label="SmartAgent. lookup" onClick={(event) => handleLookupClick(event, data?.[dataIndex]?.senderAddress)}>
                    <Tooltip title="SmartAgent. lookup">
                        <VisibilityIcon />
                    </Tooltip>
                </IconButton>
            );
        }
        return '';
    };

    const _columns = [
        {
            name: 'id',
            options: {
                display: false,
                viewColumns: false,
                filter: false
            }
        },
        {
            name: 'type',
            label: 'Channel Type',
            options: {
                sort: true,
                filter: true,
                filterType: 'custom',
                filterList: filters[FILTERINDEXES.type],
                customFilterListOptions: {
                    render: (type) => (`Channel Type: ${type}`),
                    update: chipRemoved
                },
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? '';
                        return (
                            <>
                                <InputLabel id="channelTypeLabel">Channel Type</InputLabel>
                                <Select
                                    labelId="channelTypeLabel"
                                    value={value}
                                    id="type"
                                    name="type"
                                    onChange={(e) => onChange(e.target.value !== '' ? [e.target.value] : [], index, column)}
                                >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    <MenuItem value="SMS">SMS</MenuItem>
                                    <MenuItem value="MMS">MMS</MenuItem>
                                    <MenuItem value="Instagram">Instagram</MenuItem>
                                    <MenuItem value="Messenger">Messenger</MenuItem>
                                    <MenuItem value="WeChat">WeChat</MenuItem>
                                    <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                                </Select>
                            </>
                        );
                    }
                }
            }
        },
        {
            name: 'senderAddress',
            label: 'Sender',
            options: {
                sort: true,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (filterArray) => (`Sender: ${filterArray?.[0]}`),
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.senderAddress],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? null;
                        return (
                            <TextField
                                id="search-sender"
                                label="Sender"
                                name="sender"
                                defaultValue={value}
                                onChange={(e) => onChange([e.target.value], index, column)}
                            />
                        );
                    }
                },
                customBodyRenderLite: (dataIndex) => (
                    senderFormatter(dataIndex)
                )
            }
        },
        {
            name: 'recipients',
            label: 'Recipient',
            options: {
                sort: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (filterArray) => (`Recipient: ${filterArray?.[0]}`),
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.recipients],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? null;
                        return (
                            <TextField
                                id="search-recipient"
                                label="Recipient"
                                name="recipient"
                                defaultValue={value}
                                onChange={(e) => onChange([e.target.value], index, column)}
                            />
                        );
                    }
                },
                customBodyRenderLite: (dataIndex) => (
                    recipientFormatter(dataIndex)
                )
            }
        },
        {
            name: 'reply',
            label: 'Reply',
            options: {
                sort: false,
                filter: false,
                display: customerHasDialogServiceAccess,
                viewColumns: customerHasDialogServiceAccess,
                customBodyRenderLite: (dataIndex) => (
                    replyFormatter(dataIndex)
                )
            }
        },
        {
            name: 'created',
            label: 'Created',
            options: {
                sort: true,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (filterArray) => (`Created from: ${moment(filterArray?.[0])?.format('D.M.YYYY HH:mm') ?? ''}`),
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.created],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? null;
                        return (
                            <DateTimePicker
                                autoOk
                                hideTabs
                                ampm={false}
                                value={value}
                                name="startDate"
                                format="D.M.YYYY HH:mm"
                                onChange={(date) => onChange([date], index, column)}
                                label="Created from"
                            />
                        );
                    }
                },
                customBodyRender: (value) => (
                    dateFormatter(value)
                )
            }
        },
        {
            name: 'sent',
            label: 'Sent',
            options: {
                sort: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (filterArray) => (`Created to: ${moment(filterArray?.[0])?.format('D.M.YYYY HH:mm') ?? ''}`),
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.sent],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? null;
                        return (
                            <DateTimePicker
                                autoOk
                                hideTabs
                                ampm={false}
                                value={value}
                                name="startDate"
                                format="D.M.YYYY HH:mm"
                                onChange={(date) => onChange([date], index, column)}
                                label="Created to"
                            />
                        );
                    }
                },
                customBodyRender: (value, tableMetadata) => {
                    const { rowIndex } = tableMetadata;
                    return dateFormatter(data?.[rowIndex]?.scheduledSendDateTime ?? data?.[rowIndex]?.created ?? '');
                }
            }
        },
        {
            name: 'direction',
            label: 'Direction',
            options: {
                display: true,
                filter: true,
                filterType: 'custom',
                filterList: filters[FILTERINDEXES.direction],
                customFilterListOptions: {
                    render: (status) => (`Direction: ${status}`),
                    update: chipRemoved
                },
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? '';
                        return (
                            <>
                                <InputLabel id="directionlabel">Direction</InputLabel>
                                <Select
                                    labelId="directionlabel"
                                    value={value}
                                    id="direction"
                                    name="direction"
                                    onChange={(e) => onChange(e.target.value !== '' ? [e.target.value] : [], index, column)}
                                >
                                    <MenuItem value=""><em>Both</em></MenuItem>
                                    <MenuItem value="Inbound">Inbound</MenuItem>
                                    <MenuItem value="Outbound">Outbound</MenuItem>
                                </Select>
                            </>
                        );
                    }
                }
            }
        },
        {
            name: 'charge',
            label: 'Price',
            options: {
                display: serviceHasBilling,
                sort: true,
                filter: false,
                download: true,
                customBodyRender: (value) => {
                    return String(parseFloat(value / 100).toFixed(2)).replace('.', ',');
                }
            }
        },
        {
            name: 'valueTax',
            label: 'VAT',
            options: {
                display: serviceHasBilling,
                sort: true,
                filter: false,
                download: true,
                customBodyRender: (value) => {
                    return toVatPercentage(value, true);
                }
            }
        },
        {
            name: 'content',
            label: 'Content',
            options: {
                sort: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (filterArray) => (`Content contains: ${filterArray?.[0]}`),
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.content],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? null;
                        return (
                            <TextField
                                id="search-content"
                                label="Content"
                                name="content"
                                defaultValue={value}
                                onChange={(e) => onChange([e.target.value], index, column)}
                            />
                        );
                    }
                }
            }
        },
        {
            name: 'recipients',
            label: 'Delivery status',
            options: {
                sort: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (filterArray) => (`Delivery status: ${filterArray?.[0]}`),
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.deliveryStatus],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? '';
                        const options = [
                            { label: 'Acknowledged', value: 'Acknowledged' },
                            { label: 'Created', value: 'Created' },
                            { label: 'Delivered', value: 'Delivered' },
                            { label: 'Delivery Failed', value: 'DeliveryFailed' },
                            { label: 'Null Status', value: 'NullStatus' },
                            { label: 'Queued', value: 'Queued' },
                            { label: 'Resend', value: 'Resend' },
                            { label: 'Scheduled', value: 'Scheduled' },
                            { label: 'Sent', value: 'Sent' },
                            { label: 'Send Failed', value: 'SendFailed' },
                            { label: 'Send Cancelled', value: 'SendCancelled' }
                        ];
                        return (
                            <>
                                <InputLabel id="deliveryStatusLabel">Delivery status</InputLabel>
                                <Select
                                    labelId="deliveryStatusLabel"
                                    value={value}
                                    id="status"
                                    name="status"
                                    onChange={(e) => onChange(e.target.value !== '' ? [e.target.value] : [], index, column)}
                                >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    {options.map(({ label, value }) => (
                                        <MenuItem key={value} value={value}>{label}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        );
                    }
                },
                customBodyRender: (value) => (
                    <div>
                        {value?.[0]?.status ?? ''}
                    </div>
                )
            }
        },
        {
            name: 'folders',
            options: {
                display: false,
                viewColumns: false,
                sort: false,
                filter: hasFoldersEnabled,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (filterArray) => {
                        if (hasFoldersEnabled) {
                            return (`Folder: ${folderFormatter({ id: filterArray?.[0] })}`);
                        }
                        return [];
                    },
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.folder],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? '';
                        return (
                            <>
                                <InputLabel id="folderLabel">Folder</InputLabel>
                                <Select
                                    labelId="folderLabel"
                                    value={value}
                                    id="folder"
                                    name="folder"
                                    onChange={(e) => onChange(e.target.value !== '' ? [e.target.value] : [], index, column)}
                                >
                                    <MenuItem value="_allmessages_"><em>All messages</em></MenuItem>
                                    <MenuItem value="_inbox_"><em>Inbox</em></MenuItem>
                                    {folders?.sort?.(sortByProperty('name'))?.map?.((f) => {
                                        return (
                                            <MenuItem key={f?.id} value={f?.id}>{f?.name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </>
                        );
                    }
                }
            }
        },
        {
            name: 'attachments',
            label: 'Media',
            options: {
                sort: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: () => 'Has Attachments',
                    update: chipRemoved
                },
                filterList: filters[FILTERINDEXES.attachments],
                filterOptions: {
                    logic: () => { },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index]?.[0] ?? false;
                        return (
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={value}
                                        onChange={(e) => onChange([e.target.checked], index, column)}
                                        name="attachments"
                                        inputProps={{ 'aria-label': 'Has attachments' }}
                                    />
                                )}
                                label="Has attachments"
                            />
                        );
                    }
                },
                setCellProps: () => ({
                    className: classes.mediaThumbnailCell
                }),
                customBodyRender: (value) => {
                    return (
                        <div className={classes.mediaThumbnailContainer}>
                            {value?.map((attachment) => {
                                let attachmentSrc = '';
                                const parts = attachment?.resourceLocation?.split(/[/](?!.*\/)/gi) ?? [];

                                if (parts.length === 2) {
                                    attachmentSrc = `${parts?.[0]}/thumb-${parts?.[1]}`;
                                }

                                return (
                                    <FallbackImage
                                        key={attachment?.id}
                                        src={attachmentSrc}
                                        fallbackImage={getThumbnailFallback(attachment)}
                                        alt=""
                                        className={classes.mediaThumbnail}
                                    />
                                );
                            })}
                        </div>
                    );
                }
            }
        },
        {
            name: 'lookup',
            label: 'SmartAgent.',
            options: {
                viewColumns: smartAgentAssigned,
                display: smartAgentAssigned,
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex) => (
                    smartAgentFormatter(dataIndex)
                )
            }
        }
    ];

    const persistedColumns = getPersistedColumns(_columns, customerId);

    return _columns.reduce((_columns, column, index) => {
        // Load certain properties from mui-datatable persisted state.
        let { display } = persistedColumns?.[index] ?? {};

        if (isWidget) {
            display = widgetColumns?.find((w) => w.column === column.label)?.display ?? false;
        }

        if (column.name === 'reply' && !customerHasDialogServiceAccess) {
            display = false;
        }

        if (column.name === 'lookup' && !smartAgentAssigned) {
            display = false;
        }

        return [
            ..._columns,
            {
                ...column,
                options: {
                    ...column.options,
                    ...display ? { display: display === 'true' } : {}
                }
            }
        ];
    }, []);
};

const getWidgetColumns = (columns) => {
    return defaultWidgetDisplayValues?.reduce((widgetDisplayValues, column) => {
        const displayValue = columns?.find((c) => c.label === column.column)?.display ?? true;

        return [
            ...widgetDisplayValues,
            {
                ...column,
                display: displayValue
            }
        ];
    }, []);
};

function TwoWayServiceMessageEvents({ widgetServiceId, isWidget, widgetColumns, widgetFilters, setWidgetFilterExpression, refreshWidget, setRefreshWidget, hasFoldersEnabled }) {
    const classes = useStyles();
    const { customerId, serviceId } = useParams();
    const { success: successSnack, error: errorSnack } = useSnackbar();
    const [data, setData] = React.useState([]);
    const [sortOrder, setSortOrder] = React.useState({ name: 'created', direction: 'desc' });
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const { services } = useSelector((state) => state.twowaymessaging);
    const { foldersByServiceId, foldersLoading } = useSelector((state) => state.folders);
    const serviceHasBilling = React.useMemo(() => Boolean(services?.find?.((service) => service?.id === (isWidget ? widgetServiceId : serviceId))?.hasBilling), [services, serviceId, widgetServiceId]);
    const [filters, setFilters] = React.useState((isWidget && widgetFilters.length !== 0) ? setWidgetFilterValues(widgetFilters) : getFilterListDefaultValues({ isWidget, hasFoldersEnabled, serviceId }));
    const [lookupResultsOpen, setLookupResultsOpen] = React.useState(false);
    const [shareLinkOpen, setShareLinkOpen] = React.useState(false);
    const [searchResults, setSearchresults] = React.useState([]);
    const [selectedMessageIds, setSelectedMessageIds] = React.useState([]);
    const [subtractHours, setSubtractHours] = useQueryParam('subtracthours', NumberParam);
    const [folderId, setFolderId] = useQueryParam('folderid', StringParam);

    const storageKey = React.useMemo(() => getStorageKey(customerId), [customerId]);

    const folders = React.useMemo(() => (Array.isArray(foldersByServiceId?.[serviceId]) ? foldersByServiceId[serviceId] : []), [foldersByServiceId, serviceId]);

    React.useEffect(() => {
        let fromDateFilter = null;
        let folderIdFilter = null;

        // max value is 744 hours, 31 days
        if (subtractHours && subtractHours > 0 && subtractHours <= 744) {
            setSubtractHours(undefined, 'replace');
            fromDateFilter = moment().subtract(subtractHours, 'hours');
        }
        if (hasFoldersEnabled && folderId) {
            setFolderId(undefined, 'replace');
            // check if folder from query string exists in folder array
            const folder = folders?.find?.((f) => f.id === folderId);
            if (folder || folderId === '_allmessages_') {
                folderIdFilter = folderId;
            } else {
                errorSnack('The folder doesn´t exist or has been removed.');
            }
        }
        if (fromDateFilter || folderIdFilter) {
            setFilters(getFilterListDefaultValues({ isWidget, hasFoldersEnabled, fromDate: fromDateFilter, folderId: folderIdFilter }));
        }
    }, [subtractHours, setSubtractHours, folderId, setFolderId]);

    // SmartAgent. lookup
    const { mutate: _smartAgentRequest, loading: lookupLoading } = useHttpRequest((term) => ({
        method: 'GET',
        endpoint: '/smartagent/contacts',
        params: {
            term
        }
    }));

    const smartAgentRequest = React.useCallback(_smartAgentRequest, []);

    const handleResultsClose = () => {
        setLookupResultsOpen(false);
    };

    const handleShareLinkClick = () => {
        setShareLinkOpen(true);
    };

    const handleShareLinkClose = () => {
        setShareLinkOpen(false);
    };

    const handleLookupClick = async (event, term) => {
        event.stopPropagation();

        const { payload, error, errorMessages } = await smartAgentRequest(term);

        if (!error) {
            if (Array.isArray(payload) && payload.length) {
                setLookupResultsOpen(true);
                setSearchresults(payload);
            } else {
                errorSnack('No contact details found.');
            }
        } else {
            errorSnack(errorMessages);
        }
    };

    const columns = useColumns({ data, serviceHasBilling, filters, setFilters, handleLookupClick, isWidget, widgetColumns, folders, hasFoldersEnabled });

    const [pagingOptions, setPagingOptions] = React.useState({
        page: 0,
        rowsPerPage: 10,
        count: 1
    });

    const getFilterValue = (column) => {
        return filters?.[column]?.[0] ?? '';
    };

    const from = React.useMemo(() => moment(filters?.[FILTERINDEXES.created]?.[0] ?? null)?.toJSON(), [filters]);
    const to = React.useMemo(() => moment(filters?.[FILTERINDEXES.sent]?.[0] ?? null)?.toJSON(), [filters]);

    const filterExpression = [
        { expression: `Created ge ${from}`, if: true },
        { expression: `Created le ${to}`, if: to },
        { expression: `(ServiceId eq ${isWidget ? widgetServiceId : serviceId} or SourceServiceId eq ${isWidget ? widgetServiceId : serviceId})`, if: true },
        { expression: `Direction eq '${getFilterValue(FILTERINDEXES.direction)}'`, if: getFilterValue(FILTERINDEXES.direction) !== '' },
        { expression: `Type eq '${getFilterValue(FILTERINDEXES.type)}'`, if: getFilterValue(FILTERINDEXES.type) !== '' },
        { expression: `SenderAddress eq '${getFilterValue(FILTERINDEXES.senderAddress)}'`, if: getFilterValue(FILTERINDEXES.senderAddress) !== '' },
        { expression: `Recipients/any(recipient: recipient/Address eq '${getFilterValue(FILTERINDEXES.recipients)}')`, if: getFilterValue(FILTERINDEXES.recipients) !== '' },
        { expression: `SendStatus eq '${getFilterValue(FILTERINDEXES.sendStatus)}'`, if: getFilterValue(FILTERINDEXES.sendStatus) !== '' },
        { expression: `contains(Content,'${getFilterValue(FILTERINDEXES.content)}')`, if: getFilterValue(FILTERINDEXES.content) !== '' },
        { expression: `Recipients/any(recipient: recipient/Status eq '${getFilterValue(FILTERINDEXES.deliveryStatus)}')`, if: getFilterValue(FILTERINDEXES.deliveryStatus) !== '' },
        { expression: 'Attachments/any()', if: getFilterValue(FILTERINDEXES.attachments) !== '' },
        { expression: `Folders/any(folder: folder/Id eq ${getFilterValue(FILTERINDEXES.folder)})`, if: (getFilterValue(FILTERINDEXES.folder) !== '_allmessages_' && getFilterValue(FILTERINDEXES.folder) !== '_inbox_') },
        { expression: 'Folders/$count eq 0', if: getFilterValue(FILTERINDEXES.folder) === '_inbox_' }
    ]
        .filter((f) => f.if)
        .map((f) => f.expression)
        .join(' and ');

    const { payload, loading, query: refreshMessages } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messagepersistence/messagesodata',
        params: {
            $filter: filterExpression,
            $orderBy: `${sortOrder.name} ${sortOrder.direction}`,
            $top: pagingOptions.rowsPerPage,
            $skip: pagingOptions.rowsPerPage * pagingOptions.page,
            $count: true,
            $expand: 'Recipients, Attachments, Folders'
        }
    });

    React.useEffect(() => {
        if (isWidget) {
            setWidgetFilterExpression(filterExpression);
        }
        if (refreshWidget) {
            refreshMessages();
            setRefreshWidget(false);
        }
    }, [isWidget, filterExpression, refreshWidget]);

    React.useEffect(() => {
        if (payload) {
            setPagingOptions((pagingOptions) => ({ ...pagingOptions, count: payload?.['@odata.count'] ?? 0 }));
            if (Array.isArray(payload?.value)) {
                setData(payload.value.map((item) => ({
                    ...item,
                    charge: item?.type === 'Whatsapp' ? 0 : item?.charge ?? 0,
                    valueTax: item?.type === 'Whatsapp' ? 0 : item?.valueTax ?? 0
                })));
            } else {
                setData([]);
            }
        } else {
            setPagingOptions((pagingOptions) => ({ ...pagingOptions, count: 0 }));
            setData([]);
        }
    }, [payload]);

    const handleRowClick = (row) => {
        setSelectedEvent(row);
    };

    const handleFilterSubmit = (filterList) => {
        setFilters(filterList);

        // set local storage
        const storageKey = getFiltersStorageKey(serviceId);
        localStorage.setItem(storageKey, JSON.stringify(filterList));
    };

    const handleRefreshClick = () => {
        refreshMessages();
    };

    const { mutate: createWidget } = useHttpRequest(({ settings }) => ({
        endpoint: '/widgets',
        method: 'POST',
        body: { component: 'TwoWayMessagingEventsWidget', settings, layout }
    }));

    const checkWidgetFilters = () => {
        const filterFound = filters.some((el, index) => el.length > 0 && index !== 5 && index !== 6);
        return filterFound ? filters : [];
    };

    const handleAddWidgetClick = async () => {
        const { columns: persistedColumns } = JSON.parse(localStorage.getItem(storageKey)) ?? { columns: [] };
        const widgetColumns = getWidgetColumns(persistedColumns);
        const widgetFilters = checkWidgetFilters();
        const settings = { serviceId, widgetColumns, ...(widgetFilters?.length > 0 ? { filters: widgetFilters } : {}) };
        const { error } = await createWidget({ settings });
        if (error) {
            errorSnack('Error adding widget');
        } else {
            successSnack('Widget added');
        }
    };

    const options = {
        print: false,
        selectableRowsHeader: false,
        selectableRows: hasFoldersEnabled ? 'multiple' : 'none',
        responsive: 'standard',
        search: false,
        download: false,
        rowsPerPage: pagingOptions.rowsPerPage,
        confirmFilters: true,
        serverSide: true,
        count: pagingOptions.count,
        page: pagingOptions.page,
        sortOrder,
        jumpToPage: true,
        selectToolbarPlacement: 'none',
        onRowSelectionChange: (currentSelect, allSelected) => {
            const result = allSelected.map((item) => { return data.at(item.index); });
            const selectedIds = result.map((item) => {
                return item.id;
            });
            setSelectedMessageIds(selectedIds);
        },
        onCellClick: (col, { colIndex, rowIndex }) => {
            if ((columns?.[colIndex]?.name !== 'senderAddress' && data?.[rowIndex]?.direction !== 'Outbound') ||
                (columns?.[colIndex]?.name !== 'recipients' && data?.[rowIndex]?.direction !== 'Inbound')) {
                handleRowClick(data?.[rowIndex]);
            }
        },
        customToolbar: () => (
            <>
                <Tooltip title="Share">
                    <IconButton className={classes.iconButton} style={{ order: -4 }} onClick={handleShareLinkClick}>
                        <ShareIcon style={{ transform: 'scaleX(-1)' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Refresh">
                    <IconButton className={classes.iconButton} style={{ order: -3 }} onClick={handleRefreshClick}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Pin to dashboard">
                    <IconButton className={classes.iconButton} style={{ order: -2 }} onClick={handleAddWidgetClick}>
                        <WidgetsIcon />
                    </IconButton>
                </Tooltip>
                {hasFoldersEnabled && (
                    <FoldersToolbar selectedMessageIds={selectedMessageIds} serviceId={serviceId} folders={folders ?? []} selectedFolderId={getFilterValue(FILTERINDEXES.folder)} refreshMessages={() => refreshMessages()} />
                )}
                <ExportToolbar filterExpression={filterExpression} serviceId={serviceId} folders={folders ?? []} />
            </>
        ),
        customFilterDialogFooter: (filterList, applyNewFilters) => {
            return (
                <div style={{ marginTop: '40px' }}>
                    <Button variant="contained" className={classes.button} color="primary" onClick={() => handleFilterSubmit(applyNewFilters())}>Apply Filters</Button>
                    <Button variant="contained" className={classes.button} color="primary" onClick={() => handleFilterSubmit(getFilterListDefaultValues({ isWidget, hasFoldersEnabled, serviceId, reset: true }))}>Reset</Button>
                </div>
            );
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPagingOptions({ ...pagingOptions, page: tableState.page });
                    break;
                case 'changeRowsPerPage':
                    setPagingOptions({ ...pagingOptions, page: 0, rowsPerPage: tableState.rowsPerPage });
                    break;
                default:
                    break;
            }
        },
        onFilterChange: (column, filterList, type) => {
            if (type === 'custom') {
                setPagingOptions({ ...pagingOptions, page: 0 });
            }
        },
        onColumnSortChange: (name, direction) => {
            setSortOrder({ name, direction });
        },
        ...(!isWidget ? { storageKey } : {})
    };

    const handleClose = () => {
        setSelectedEvent(null);
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <Suspense fallback={<LinearProgress />}>
            <Card style={isWidget && { boxShadow: 'none' }}>
                {(lookupLoading || foldersLoading) && <LinearProgress />}
                <MuiThemeProvider theme={(theme) => ({
                    ...theme,
                    overrides: {
                        ...theme.overrides,
                        MUIDataTableFilter: {
                            resetLink: {
                                display: 'none'
                            }
                        },
                        MUIDataTableFilterList: {
                            root: {
                                display: isWidget ? 'none' : 'auto'
                            },
                            chip: {
                                marginBottom: '10px',
                                border: '1px solid #e2e6e9'
                            }
                        },
                        MuiToolbar: {
                            root: {
                                display: isWidget ? 'none' : 'auto',
                                minHeight: '0px !important'
                            }
                        }
                    }
                })}
                >
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
                <PopupDialog
                    open={Boolean(selectedEvent)}
                    onClose={handleClose}
                    message={selectedEvent}
                />
                <ShareLinkPopup open={shareLinkOpen} handleClose={handleShareLinkClose} hasFoldersEnabled={hasFoldersEnabled} folders={folders ?? []} />
                <SearchResults open={lookupResultsOpen} setOpen={handleResultsClose} searchResults={searchResults ?? []} />
            </Card>
        </Suspense>
    );
}

TwoWayServiceMessageEvents.propTypes = {
    widgetServiceId: PropTypes.string,
    isWidget: PropTypes.bool,
    widgetColumns: PropTypes.array,
    widgetFilters: PropTypes.array,
    setWidgetFilterExpression: PropTypes.func,
    refreshWidget: PropTypes.string,
    setRefreshWidget: PropTypes.func,
    hasFoldersEnabled: PropTypes.bool
};

export default TwoWayServiceMessageEvents;