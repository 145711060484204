import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import ScheduleIcon from '@material-ui/icons/ScheduleRounded';
import { Box, DialogContent, DialogTitle, MuiThemeProvider } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/en-gb';
import AttachmentIcon from '@material-ui/icons/AttachmentRounded';
import ReactPlayer from 'react-player/lazy';
import SenderIcon from '@material-ui/icons/SmsRounded';
import ChannelIcon from '@material-ui/icons/ChatBubbleRounded';
import DirectionIcon from '@material-ui/icons/CompareArrowsRounded';
import { useHttpRequest } from 'src/utils/httpClient';
import { attachmentsBlobContainer } from 'src/components/TwoWayMessaging/TwoWayServiceMessageEvents';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import MUIDataTable from 'mui-datatables';
import FallbackImage from 'src/components/FallbackImage';

export const FilterDialogFooter = () => <Box minWidth={300} />;

const dateFormatter = (rowData) => {
    const date = moment.utc(rowData).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm:ss') : '';
};

const pipelineHistoryTableTheme = (baseTheme) => ({
    ...baseTheme,
    overrides: {
        ...baseTheme.overrides,
        MUIDataTable: {
            paper: {
                width: '100%'
            }
        },
        MuiTableCell: {
            ...baseTheme.overrides.MuiTableCell,
            root: {
                ...baseTheme.overrides.MuiTableCell.root,
                padding: 4,
                fontSize: 13,
                maxWidth: 'unset'
            }
        },
        MUIDataTableBodyCell: {
            root: {

            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    header: {
        width: '100%',
        padding: theme.spacing(2)
    },
    dialogContent: {
        overflow: 'auto'
    },
    messageContent: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden'
    },
    messageContentTextContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        width: '100%'
    },
    messageContentTextbox: {
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    messageContentChipContainer: {
        padding: theme.spacing(1)
    },
    messageContentMediaContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
        padding: theme.spacing(2)
    },
    messageContentMedia: {
        cursor: 'pointer'
    },
    messageContentMediaThumbnail: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 200
        }
    },
    messageContentMediaVideoWrapper: {
        height: 300
    },
    chip: {
        margin: theme.spacing(1)
    },
    pipelineHistoryTableMessageColumn: {
        [theme.breakpoints.up('md')]: {
            width: '70%'
        }
    }
}));

// eslint-disable-next-line
const ReactPlayerWrapper = React.forwardRef(({ children }, ref) => {
    const classes = useStyles();

    return <div className={classes.messageContentMediaVideoWrapper}>{children}</div>;
});

const MediaComponent = ({ attachment, onClick }) => {
    const classes = useStyles();
    let _component;

    if (attachment?.mimeType?.indexOf('image') > -1) {
        let attachmentSrc = '';
        const parts = attachment?.resourceLocation?.split(/[/](?!.*\/)/gi) ?? [];

        if (parts.length === 2) {
            attachmentSrc = `${parts?.[0]}/thumb-${parts?.[1]}`;
        }

        _component = (
            <FallbackImage
                src={attachmentSrc}
                alt=""
                className={classes.messageContentMediaThumbnail}
                onClick={onClick}
            />
        );
    } else if (attachment?.mimeType?.indexOf('video') > -1) {
        _component = (
            <ReactPlayer
                url={attachment?.resourceLocation}
                controls
                light
                muted
                playing
                wrapper={ReactPlayerWrapper}
            />
        );
    } else {
        _component = <AttachmentIcon />;
    }

    return <div className={classes.messageContentMedia}>{_component}</div>;
};

MediaComponent.propTypes = {
    attachment: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

const PopupDialog = ({ onClose, open, message }) => {
    const classes = useStyles();
    const [parentMessage, setParentMessage] = React.useState(null);
    const [pipelineHistory, setPipelineHistory] = React.useState([]);
    const hasAttachment = message?.attachments?.length > 0 ?? false;
    const attachment = message?.attachments?.[0] ?? null;

    const { payload: parentMessagePayload, mutate: fetchParentMessage, loading: parentMessageLoading } = useHttpRequest(({ id, created }) => ({
        method: 'GET',
        endpoint: '/messagepersistence/messagesodata',
        params: {
            $filter: `ParentMessageId eq ${id} and Created ge ${moment(created).toJSON()} and Created le ${moment(created).add(1, 'day').toJSON()}`,
            $expand: 'Recipients',
            $top: 1
        }
    }));

    const { mutate: fetchPipelineHistory, payload: pipelineHistoryPayload, loading: pipelineHistoryLoading } = useHttpRequest((messageId) => ({
        method: 'GET',
        endpoint: `/messaging/services/twoway/pipelinehistory/${messageId}`
    }));

    // Store parentmessage to state
    React.useEffect(() => {
        if (parentMessagePayload && parentMessagePayload.value && parentMessagePayload.value.length > 0) {
            setParentMessage(parentMessagePayload?.value?.[0] ?? null);
        } else {
            setParentMessage(null);
        }
    }, [parentMessagePayload]);

    // Store pipelinehistory to state
    React.useEffect(() => {
        setPipelineHistory(Array.isArray(pipelineHistoryPayload) ? pipelineHistoryPayload : []);
    }, [pipelineHistoryPayload]);

    // Fetch parentmessage if selected message direction is Inbound
    React.useEffect(() => {
        setParentMessage(null);
        setPipelineHistory([]);

        if (message && message?.direction === 'Inbound') {
            fetchPipelineHistory(message?.id);
            fetchParentMessage(message);
        }
    }, [message, fetchParentMessage, fetchPipelineHistory]);

    const handleClose = () => {
        onClose();
    };

    const handleMediaThumbnailClick = () => {
        if (hasAttachment) {
            window.open(attachment?.resourceLocation, '_blank');
        }
    };

    const pipelineHistoryColumns = [
        {
            name: 'timestamp',
            label: 'Timestamp',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => dateFormatter(value)
            }
        },
        {
            name: 'logLevel',
            label: 'Log Level',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'pipelineActionType',
            label: 'Action Type',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'message',
            label: 'Message',
            options: {
                filter: false,
                sort: true,
                setCellHeaderProps: () => ({
                    className: classes.pipelineHistoryTableMessageColumn
                })
            }
        }
    ];

    const pipelineHistoryTableOptions = {
        elevation: 0,
        print: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        responsive: 'standard',
        filter: true,
        search: false,
        download: false,
        viewColumns: false,
        fixedHeader: true,
        rowsPerPage: 10,
        sortOrder: { name: 'timestamp', direction: 'asc' }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="Twoway messaging events popup"
            open={open}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle disableTypography>
                <Typography gutterBottom variant="h4">
                    Message Event
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className={classes.messageContent}>
                    <div className={classes.messageContentTextContainer}>
                        <Card className={classes.messageContentTextbox}>
                            <Typography variant="h4" gutterBottom>Message Content</Typography>
                            <Typography variant="body1">
                                {message?.content}
                            </Typography>
                        </Card>
                        {message?.direction === 'Inbound' && (
                            <>
                                <Card className={classes.messageContentTextbox}>
                                    <Typography variant="h4" gutterBottom>Reply message</Typography>
                                    {parentMessageLoading && <DelayedLinearProgress />}
                                    <Typography variant="body1">
                                        {parentMessage?.content}
                                    </Typography>
                                </Card>
                                <Card className={classes.messageContentTextbox}>
                                    <Typography variant="h4" gutterBottom>Pipeline Action History</Typography>
                                    {pipelineHistoryLoading && <DelayedLinearProgress />}
                                    <MuiThemeProvider theme={pipelineHistoryTableTheme}>
                                        <MUIDataTable
                                            data={pipelineHistory}
                                            columns={pipelineHistoryColumns}
                                            options={pipelineHistoryTableOptions}
                                        />
                                    </MuiThemeProvider>
                                </Card>
                            </>
                        )}
                        <div className={classes.messageContentChipContainer}>
                            <Chip icon={<SenderIcon />} className={classes.chip} label={`Sender: ${message?.senderAddress}`} />
                            {message?.serviceType === 'TwoWay' && (
                                <Chip icon={<SenderIcon />} className={classes.chip} label={`Recipient: ${message?.recipients?.[0]?.address}`} />
                            )}
                            <Chip icon={<ChannelIcon />} className={classes.chip} label={`Channel: ${message?.type}`} />
                            <Chip icon={<ScheduleIcon />} className={classes.chip} label={`Received: ${dateFormatter(message?.created)}`} />
                            <Chip icon={<DirectionIcon />} className={classes.chip} label={`Direction: ${message?.direction}`} />
                        </div>
                    </div>
                    {hasAttachment && (
                        <div className={classes.messageContentMediaContainer}>
                            <MediaComponent attachment={attachment} attachmentsBlobContainer={attachmentsBlobContainer} onClick={handleMediaThumbnailClick} />
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

PopupDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.object
};

export default PopupDialog;