import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Chip, Tooltip, Typography, IconButton } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/ReplayRounded';
import MessagingEvents from 'src/components/TwoWayMessaging/TwoWayServiceMessageEvents';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        border: '1px solid #e2e6e9',
        width: 'fit-content'
    },
    tooltip: {
        color: '#fff',
        fontSize: '1.3em'
    },
    scroller: {
        overflow: 'auto',
        height: 'inherit'
    }
}));

const TwoWayMessagingEventsWidget = ({ settings }) => {
    const classes = useStyles();
    const history = useHistory();
    const { customerId } = useParams();
    const { serviceId, widgetColumns, filters } = settings;
    const { services: twoWayServices } = useSelector((state) => state.twowaymessaging);
    const [filterExpression, setFilterExpression] = React.useState('');
    const [refreshWidget, setRefreshWidget] = React.useState(false);

    const handleServiceOpenClick = () => {
        history.push(`/${customerId}/services/twowaymessaging/info/${serviceId}/events`);
    };

    const getServiceName = () => {
        return twoWayServices?.find((s) => s.id === serviceId)?.name ?? '';
    };

    return (
        <>
            <div>
                <Chip className={classes.chip} label={`Service name: ${getServiceName()}`} onClick={handleServiceOpenClick} />
                {filters && (
                    <Tooltip title={<Typography className={classes.tooltip}>{filterExpression}</Typography>}>
                        <Chip className={classes.chip} label="With filters" />
                    </Tooltip>
                )}
                <Tooltip title="Refresh" onClick={() => setRefreshWidget(true)} style={{ position: 'absolute', right: '0' }}>
                    <IconButton>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.scroller}>
                <MessagingEvents
                    widgetServiceId={serviceId}
                    isWidget
                    widgetColumns={widgetColumns ?? []}
                    widgetFilters={filters ?? []}
                    setWidgetFilterExpression={setFilterExpression}
                    refreshWidget={refreshWidget}
                    setRefreshWidget={setRefreshWidget}
                />
            </div>
        </>
    );
};

export const size = {
    xl: {
        w: 5,
        h: 3
    },
    lg: {
        w: 6,
        h: 3
    },
    md: {
        w: 6,
        h: 3
    },
    xs: {
        w: 8,
        h: 3
    },
    xxs: {
        w: 8,
        h: 3
    }
};

TwoWayMessagingEventsWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export default TwoWayMessagingEventsWidget;