import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Card, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import MouseIcon from '@material-ui/icons/MouseRounded';
import PropTypes from 'prop-types';
import GoogleMap from 'src/components/Code/GoogleMap';
import { useHttpGetRequest } from 'src/utils/httpClient';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        flex: 1
    },
    cardGrid: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    typeGrid: {
        width: '100%',
        flexBasis: '50px',
        backgroundColor: theme.palette.background.gray,
        color: theme.palette.white,
        '& *': {
            color: theme.palette.white
        }
    },
    gridItem: {
        margin: theme.spacing(0),
        padding: theme.spacing(2, 2),
        zIndex: 10,
        backgroundColor: theme.palette.background.gray
    },
    mapContainer: {
        position: 'absolute',
        top: theme.spacing(6),
        height: `calc(100% - ${theme.spacing(6)}px)`,
        width: '100%'
    },
    button: {
        color: '#fff',
        position: 'absolute',
        top: '0',
        right: '0'
    }
}));

const MapWidget = ({ serviceId, serviceName }) => {
    const classes = useStyles();
    const [codes, setCodes] = React.useState([]);
    const [allowZoomOnScroll, setAllowZoomOnScroll] = React.useState(false);

    const { payload, loading: codesLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/code/codes?service-id=${serviceId}`
    });

    React.useEffect(() => {
        if (payload) {
            setCodes(Array.isArray(payload) ? payload : []);
        }
    }, [payload]);

    if (codesLoading) {
        return <LinearProgress />;
    }

    const handleZoomOnScrollClick = () => {
        if (allowZoomOnScroll) {
            setAllowZoomOnScroll(false);
        } else {
            setAllowZoomOnScroll(true);
        }
    };

    return (
        <Suspense fallback={<LinearProgress />}>
            <Card className={classes.card}>
                <div className={classes.cardGrid}>
                    <div className={classes.typeGrid}>
                        <Grid container item xs={12} alignItems="center">
                            <Grid container item xs={12} justifyContent="flex-start" className={classes.gridItem}>
                                <Typography variant="h5">{`Redeemed codes: ${serviceName}`}</Typography>
                                <IconButton aria-label="allow-scroll" onClick={handleZoomOnScrollClick} className={classes.button}>
                                    <Tooltip title={allowZoomOnScroll ? 'Disable zoom on scroll' : 'Allow zoom on scroll'}>
                                        <MouseIcon />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <div className={classes.mapContainer}>
                                    <GoogleMap codes={codes} allowZoomOnScroll={allowZoomOnScroll} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Card>
        </Suspense>
    );
};

MapWidget.propTypes = {
    serviceId: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired
};

export default MapWidget;