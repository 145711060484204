import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useHttpFetchRequest, useHttpRequest } from 'src/utils/httpClient';
import { useSnackbar } from 'src/components/Snackbar';
import ConfirmDialog from 'src/components/ConfirmDialog';
import SaveIcon from '@material-ui/icons/SaveRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import CancelIcon from '@material-ui/icons/CloseRounded';
import AttachFileIcon from '@material-ui/icons/AttachFileRounded';
import { useDispatch, useSelector } from 'react-redux';
import { refreshMessageTemplates } from 'src/actions/messageTemplateActions';
import OutlinedContainer from 'src/components/FormComponents/OutlinedContainer';
import { DropzoneArea } from 'material-ui-dropzone';
import ExternalLink from 'src/components/ExternalLink';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import { getMessageCount } from 'src/utils/messageFunctions';
import parseCsv from 'src/utils/parseCsv';
import WhatsappTemplateButtons from 'src/components/MessageTemplates/WhatsappTemplateButtons';
import InputAdornment from '@material-ui/core/InputAdornment';
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import uuid from 'uuid';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3)
    },
    formControl: {
        flex: 1,
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    whatsappTemplateSubmittedDialog: {
        minWidth: '450px'
    },
    dropZone: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 50,
        margin: theme.spacing(2, 0),
        '& > .MuiDropzoneArea-textContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& > .MuiDropzoneArea-textContainer > svg': {
            height: 20,
            width: 20
        }
    },
    dropZoneFileSelected: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 50,
        margin: theme.spacing(2, 0),
        '& > .MuiDropzoneArea-textContainer': {
            display: 'none'
        }
    },
    previewGrid: {
        display: 'flex',
        justifyContent: 'center',
        padding: 24,
        '& > *': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        }
    },
    counterContainer: {
        textAlign: 'right'
    },
    categoryMenuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    }
}));

const CopiedToClipboardTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.white,
        '& *': {
            color: theme.palette.white
        }
    },
}))(Tooltip);

const languageOptions = [
    { label: 'Danish', value: 'da' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English (UK)', value: 'en-gb' },
    { label: 'English (US)', value: 'en-us' },
    { label: 'Estonian', value: 'et' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Italian', value: 'it' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Spanish', value: 'es' },
    { label: 'Russian', value: 'ru' }
];

/* https://developers.facebook.com/docs/whatsapp/api/messages/message-templates/ */
/* https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/ */

const categoryOptions = [
    { label: 'Authentication', value: 'Authentication', info: 'Send codes that allow your customers to securely access their accounts.' },
    { label: 'Marketing', value: 'Marketing', info: 'Send promotional offers, product announcements, and more to increase awareness and engagement.' },
    { label: 'Utility', value: 'Utility', info: 'Send account updates, order updates, alerts, and more to share important information.' },
];

const defaultValues = {
    name: '',
    displayName: '',
    language: 'en-gb',
    content: '',
    buttons: [],
    category: ''
};

const generateTemplateId = (text, identityNumber) => {
    if (typeof text !== 'string' || typeof identityNumber !== 'string') {
        throw new Error('generateTemplateId inputparameters need to be of type string');
    }
    const _text = text.trim().replace(/\s+/g, '_').replace(/[^-\w]+/g, '').toLowerCase();

    return [identityNumber, _text].filter((item) => item !== '').join('_');
};

const getChannelType = (initialChannelType, editedTemplate) => {
    switch (editedTemplate?.type) {
        case 'InstantMessage':
            return editedTemplate?.extensionParameters?.protocol ?? initialChannelType;
        default:
            return editedTemplate?.type ?? initialChannelType ?? 'SMS';
    }
};

const MessageTemplateDialog = ({ channelType: initialChannelType, templateContent, open, setOpen, editedTemplate, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { customerInputSources, customerInputSourcesLoading } = useSelector((state) => state.twowaymessaging);
    const { error: errorSnack, success: successSnack } = useSnackbar();
    const [channelType, setChannelType] = React.useState('SMS');
    const [whatsappIdentity, setWhatsappIdentity] = React.useState('');
    const [whatsappIdentityProvider, setWhatsappIdentityProvider] = React.useState('');
    const [messageTemplate, setMessageTemplate] = React.useState(defaultValues);
    const [confirmDeletionOfTemplate, setConfirmDeletionOfTemplate] = React.useState(false);
    const [whatsappTemplateSubmittedDialogVisible, setWhatsappTemplateSubmittedDialogVisible] = React.useState(false);
    const [returnValue, setReturnValue] = React.useState(null);
    const [selectedWhatsappAttachment, setSelectedWhatsappAttachment] = React.useState(null);
    const { handleCopy } = useCopyToClipboard();
    const [showTooltip, setShowTooltip] = React.useState(false);

    const customerHasWhatsapp = React.useMemo(() => customerInputSources.some((cis) => cis?.type === 'Whatsapp'), [customerInputSources]);

    // Set default values on open, or edited template values
    React.useEffect(() => {
        let mounted = true;

        if (open && mounted) {
            setReturnValue(null);
            setChannelType(getChannelType(initialChannelType, editedTemplate));
            setWhatsappIdentity(editedTemplate?.extensionParameters?.identityNumber ?? '');
            setWhatsappIdentityProvider('');
            setMessageTemplate({
                ...defaultValues,
                name: editedTemplate?.id ?? '',
                displayName: editedTemplate?.displayName ?? '',
                language: editedTemplate?.language ?? 'en-gb',
                content: editedTemplate?.extensionParameters?.rawContent ?? editedTemplate?.extensionParameters?.content ?? templateContent ?? '',
                category: editedTemplate?.extensionParameters?.category ?? '',
                attachmentUrl: editedTemplate?.extensionParameters?.attachmentUrl ?? '',
                buttons: editedTemplate?.extensionParameters?.Buttons?.map?.((button) => ({ ...button, _key: uuid() })) ?? []
            });
        }

        return () => { mounted = false; };
    }, [open, editedTemplate, initialChannelType, templateContent]);

    React.useEffect(() => {
        if (channelType === 'Whatsapp' && whatsappIdentity !== '') {
            setMessageTemplate((messageTemplate) => ({
                ...messageTemplate,
                name: generateTemplateId(messageTemplate.displayName, whatsappIdentity)
            }));
        } else {
            setMessageTemplate((messageTemplate) => ({
                ...messageTemplate,
                name: generateTemplateId(messageTemplate.displayName, '')
            }));
        }
    }, [channelType, whatsappIdentity]);

    const { mutate: createSmsTemplate, loading: createSmsTemplateLoading } = useHttpRequest((body) => ({
        method: 'POST',
        endpoint: '/messaging/templates/sms',
        body
    }));

    const { mutate: createQuickReplyTemplate, loading: createQuickReplyTemplateLoading } = useHttpRequest((body) => ({
        method: 'POST',
        endpoint: '/messaging/templates/quick-reply',
        body
    }));

    const { mutate: createWhatsappTemplate, loading: createWhatsappTemplateLoading } = useHttpRequest((body) => ({
        method: 'POST',
        endpoint: '/messaging/templates/instant-message',
        body: {
            ...body,
            protocol: 'Whatsapp'
        }
    }));

    const { mutate: updateTemplate, loading: updateTemplateLoading } = useHttpRequest(({ id, ...rest }) => ({
        method: 'PATCH',
        endpoint: `/messaging/templates/${id}`,
        body: {
            ...rest
        }
    }));

    const { mutate: deleteTemplate } = useHttpRequest((id) => ({
        method: 'PATCH',
        endpoint: `/messaging/templates/${id}`,
        body: {
            newState: 'Disabled'
        }
    }));

    const { mutate: uploadMediaAttachement } = useHttpFetchRequest(({ body, expirationInHours }) => ({
        method: 'POST',
        endpoint: '/utils/media',
        body,
        params: {
            ...(expirationInHours ? { expirationInHours } : {})
        }
    }));

    const { mutate: getWhatsappMapping, loading: whatsappMappingLoading } = useHttpRequest((whatsappIdentity) => ({
        method: 'GET',
        endpoint: `/messaging/templates/whatsapp/mappings/${whatsappIdentity}`
    }));

    const onChange = (e) => {
        setMessageTemplate({
            ...messageTemplate,
            [e.target.name]: e.target.value
        });
    };

    const onWhatsappIdentityChange = async (e) => {
        if (e.target.value && e.target.value !== '') {
            const { error, payload } = await getWhatsappMapping(e.target.value);

            setWhatsappIdentityProvider(error ? '' : payload?.find?.((mapping) => mapping?.protocol === 'Whatsapp')?.brokerName ?? '');
            setWhatsappIdentity(e.target.value);
        } else {
            setWhatsappIdentity('');
        }
    };

    const onDisplaynameChange = (e) => {
        setMessageTemplate({
            ...messageTemplate,
            ...editedTemplate ? {} : {
                // Generate template name if creating new template
                name: generateTemplateId(e.target.value, channelType === 'Whatsapp' ? whatsappIdentity : '')
            },
            displayName: e.target.value
        });
    };

    const onWhatsappAttachmentChanged = (selectedFiles) => {
        if (selectedFiles?.length > 0) {
            const parsedCsv = parseCsv(selectedFiles?.[0]);
            if (parsedCsv) {
                setSelectedWhatsappAttachment(parsedCsv);
                return;
            }
            errorSnack('Error parsing Csv file');
        }
        setSelectedWhatsappAttachment(null);
    };

    const onButtonsChanged = (buttons) => {
        setMessageTemplate({
            ...messageTemplate,
            buttons
        });
    };

    const handleCancelClick = () => {
        setOpen(false);
    };

    const handleSaveClick = async () => {
        if (editedTemplate) {
            // Update existing template
            const { error, errorMessages } = await updateTemplate({
                id: editedTemplate.id,
                displayName: messageTemplate.displayName,
                ...(channelType === 'SMS' ? { content: messageTemplate.content } : {})
            });
            if (error) {
                errorSnack(errorMessages);
            } else {
                successSnack('Template update');
                dispatch(refreshMessageTemplates());
                setReturnValue(editedTemplate.id);
                setOpen(false);
            }
        } else if (channelType === 'SMS') {
            // Create new SMS template
            const { error, errorMessages } = await createSmsTemplate({ ...messageTemplate, id: messageTemplate.name });
            if (error) {
                errorSnack(errorMessages);
            } else {
                successSnack('SMS template created');
                dispatch(refreshMessageTemplates());
                setReturnValue(messageTemplate.name);
                setOpen(false);
            }
        } else if (channelType === 'QuickReply') {
            // Create new Quickreply template
            const { error, errorMessages } = await createQuickReplyTemplate({ ...messageTemplate, id: messageTemplate.name });
            if (error) {
                errorSnack(errorMessages);
            } else {
                successSnack('Quickreply template created');
                dispatch(refreshMessageTemplates());
                setReturnValue(messageTemplate.name);
                setOpen(false);
            }
        } else if (channelType === 'Whatsapp') {
            let attachmentUrl = null;

            if (selectedWhatsappAttachment) {
                const body = new FormData();
                body.append('files', selectedWhatsappAttachment, selectedWhatsappAttachment.name);

                const { error, errorMessages, payload } = await uploadMediaAttachement({ body });
                if (!error) {
                    attachmentUrl = payload?.url;
                } else {
                    errorSnack(errorMessages);
                }
            }

            // Create new Whatsapp template
            const { error, errorMessages } = await createWhatsappTemplate({
                id: messageTemplate.name,
                rawContent: messageTemplate.content,
                displayName: messageTemplate.displayName,
                language: messageTemplate.language,
                category: messageTemplate.category,
                identityNumber: whatsappIdentity,
                identityProvider: whatsappIdentityProvider,
                attachmentUrl,
                buttons: messageTemplate.buttons
            });
            if (error) {
                errorSnack(errorMessages);
            } else {
                setWhatsappTemplateSubmittedDialogVisible(true);
                dispatch(refreshMessageTemplates());
                setReturnValue(messageTemplate.name);
                setOpen(false);
            }
        }
    };

    const handleDeleteClick = () => {
        setConfirmDeletionOfTemplate(true);
    };

    const handleDeleteTemplateConfirmed = async () => {
        const { error, errorMessages } = await deleteTemplate(editedTemplate.id);
        if (error) {
            errorSnack(errorMessages.formatted);
        } else {
            dispatch(refreshMessageTemplates());
            setOpen(false);
        }
    };

    const handleWhatsappTemplateDialogClose = () => {
        setWhatsappTemplateSubmittedDialogVisible(false);
    };

    const onExited = () => {
        setMessageTemplate(defaultValues);
        setWhatsappIdentity('');
        setShowTooltip(false);
        handleClose(returnValue);
    };

    const handleCopyClick = (name) => {
        handleCopy(name);
        setShowTooltip(true);
    };

    const endAdornment = () => {
        return (
            <InputAdornment position="end">
                <CopiedToClipboardTooltip
                    arrow
                    placement="top"
                    open={showTooltip}
                    title={(
                        <Typography variant="h5">Copied to clipboard</Typography>
                    )}
                >
                    <IconButton
                        aria-label={`Copy ${channelType === 'Whatsapp' ? 'Name / Template ID' : 'Name'} to clipboard`}
                        onClick={() => handleCopyClick(messageTemplate.name)}
                        edge="end"
                    >
                        <CopyIcon />
                    </IconButton>
                </CopiedToClipboardTooltip>
            </InputAdornment>
        );
    };

    return (
        <>
            <Dialog open={open} TransitionProps={{ onExited }} maxWidth="md" fullWidth aria-labelledby="message-template-title">
                <DialogTitle id="message-template-title">{editedTemplate ? 'Edit message template' : 'Create message template'}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        className={classes.formControl}
                        disabled={Boolean(editedTemplate) || Boolean(initialChannelType)}
                        select
                        name="messageTemplateType"
                        id="messageTemplateType"
                        variant="outlined"
                        label="Template Type"
                        SelectProps={{
                            value: channelType,
                            onChange: (e) => setChannelType(e.target.value)
                        }}
                    >
                        <MenuItem value="SMS">SMS</MenuItem>
                        <MenuItem value="QuickReply">Quick-Reply</MenuItem>
                        <MenuItem disabled={!customerHasWhatsapp} value="Whatsapp">WhatsApp</MenuItem>
                    </TextField>

                    {channelType === 'Whatsapp' && editedTemplate && (
                        <TextField
                            className={classes.formControl}
                            disabled
                            name="whatsappIdentity"
                            id="whatsappIdentity"
                            variant="outlined"
                            label="WhatsApp Number"
                            defaultValue={whatsappIdentity}
                        />
                    )}
                    {channelType === 'Whatsapp' && !editedTemplate && (
                        <TextField
                            className={classes.formControl}
                            disabled={Boolean(editedTemplate)}
                            select
                            name="whatsappIdentity"
                            id="whatsappIdentity"
                            variant="outlined"
                            SelectProps={{
                                displayEmpty: true,
                                value: whatsappIdentity,
                                onChange: onWhatsappIdentityChange
                            }}
                        >
                            {customerInputSourcesLoading && <MenuItem value={whatsappIdentity}>Loading WhatsApp Numbers...</MenuItem>}

                            {!customerInputSourcesLoading && <MenuItem value="">Select WhatsApp Number</MenuItem>}
                            {customerInputSources?.filter((c) => c.type === 'Whatsapp')?.map((c) => (
                                <MenuItem key={c.address} value={c.address}>{c.address}</MenuItem>
                            ))}
                        </TextField>
                    )}
                    {channelType === 'Whatsapp' && whatsappMappingLoading && <DelayedLinearProgress />}
                    {channelType === 'Whatsapp' && (whatsappIdentity === '' || whatsappMappingLoading) ? '' : (
                        <>
                            <TextField
                                className={classes.formControl}
                                disabled
                                variant="outlined"
                                label={channelType === 'Whatsapp' ? 'Name / Template ID' : 'Name'}
                                value={messageTemplate.name}
                                helperText={channelType === 'Whatsapp' && 'Use this Template ID value, when sending template messages via the gateway'}
                                InputProps={{
                                    endAdornment: (
                                        endAdornment()
                                    )
                                }}
                            />
                            <TextField
                                className={classes.formControl}
                                variant="outlined"
                                label="Displayname"
                                inputProps={{
                                    minLength: 0,
                                    maxLength: 100
                                }}
                                value={messageTemplate.displayName}
                                onChange={onDisplaynameChange}
                            />
                            <TextField
                                className={classes.formControl}
                                disabled={Boolean(editedTemplate)}
                                select
                                name="language"
                                id="language"
                                variant="outlined"
                                SelectProps={{
                                    value: messageTemplate.language,
                                    onChange: (e) => setMessageTemplate({ ...messageTemplate, language: e.target.value })
                                }}
                            >
                                {languageOptions.map((language) => <MenuItem key={language.value} value={language.value}>{language.label}</MenuItem>)}
                                {messageTemplate.language && !languageOptions.find((opt) => opt.value === messageTemplate.language) && (
                                    <MenuItem disabled value={messageTemplate.language}>{messageTemplate.language}</MenuItem>
                                )}
                            </TextField>
                            {channelType === 'Whatsapp' && (
                                <>
                                    <TextField
                                        className={classes.formControl}
                                        disabled={Boolean(editedTemplate)}
                                        select
                                        name="category"
                                        id="category"
                                        variant="outlined"
                                        SelectProps={{
                                            displayEmpty: true,
                                            value: messageTemplate.category,
                                            onChange: (e) => setMessageTemplate({ ...messageTemplate, category: e.target.value })
                                        }}
                                    >
                                        <MenuItem value="">Select category</MenuItem>
                                        {categoryOptions.map((category) => (
                                            <MenuItem className={classes.categoryMenuItem} key={category.value} value={category.value} component="div">
                                                <Typography variant="body1">{category.label}</Typography>
                                                <Typography variant="caption">{category.info}</Typography>
                                            </MenuItem>
                                        ))}
                                        {messageTemplate.category && !categoryOptions.find((opt) => opt.value === messageTemplate.category) && (
                                            <MenuItem disabled value={messageTemplate.category}>{messageTemplate.category}</MenuItem>
                                        )}
                                    </TextField>
                                    {Boolean(editedTemplate) && messageTemplate.attachmentUrl !== '' && (
                                        <OutlinedContainer className={classes.formControl} label="WhatsApp Attachment">
                                            <ExternalLink style={{ textDecoration: 'underline' }} href={messageTemplate.attachmentUrl} alt="WhatsApp Attachment">Link to WhatsApp Attachment</ExternalLink>
                                        </OutlinedContainer>
                                    )}
                                    {!editedTemplate && (
                                        <OutlinedContainer className={classes.formControl} label="WhatsApp Attachment">
                                            <DropzoneArea
                                                dropzoneClass={selectedWhatsappAttachment ? classes.dropZoneFileSelected : classes.dropZone}
                                                onChange={onWhatsappAttachmentChanged}
                                                acceptedFiles={['.png', '.jpg', '.jpeg', '.ogg', 'mp3', 'amr', '.mp4', '.pdf', '.vcard']}
                                                fileObjects={selectedWhatsappAttachment ? [selectedWhatsappAttachment] : []}
                                                filesLimit={1}
                                                dropzoneText={selectedWhatsappAttachment ? '' : 'Drag and Drop a file here or click'}
                                                Icon={AttachFileIcon}
                                                maxFileSize={167772016}
                                                useChipsForPreview
                                                showFileNames
                                                showFileNamesInPreview
                                                previewGridClasses={{ container: classes.previewGrid }}
                                                alertSnackbarProps={{
                                                    anchorOrigin: {
                                                        horizontal: 'center', vertical: 'top'
                                                    }
                                                }}
                                            />
                                        </OutlinedContainer>
                                    )}
                                    <WhatsappTemplateButtons disabled={Boolean(editedTemplate)} buttons={messageTemplate.buttons} onChange={onButtonsChanged} />
                                </>
                            )}
                            <TextField
                                className={classes.formControl}
                                disabled={Boolean(editedTemplate) && channelType === 'Whatsapp'}
                                name="content"
                                id="content"
                                variant="outlined"
                                label="Content"
                                placeholder="Message template content"
                                helperText={channelType === 'SMS' ? 'Add placeholders in the form of $(placeholderName)' : 'Add placeholders in the form of {{placeholderName}}'}
                                multiline
                                minRows={5}
                                maxRows={10}
                                value={messageTemplate.content}
                                onChange={onChange}
                            />
                            <div className={classes.counterContainer}>
                                <Typography variant="h5">
                                    {messageTemplate?.content?.includes('$(') ? 'Character count (estimate):' : 'Character count:'}
                                    {' '}
                                    {messageTemplate?.content?.length ?? 0}
                                </Typography>
                                {channelType === 'SMS' ? (
                                    <Typography variant="h5">
                                        {messageTemplate?.content?.includes('$(') ? 'SMS message count (estimate):' : 'SMS message count:'}
                                        {' '}
                                        {getMessageCount(messageTemplate?.content ?? '')}
                                    </Typography>
                                ) : ''}
                            </div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {editedTemplate && (
                        <IconButton aria-label="delete" onClick={handleDeleteClick} style={{ marginRight: 'auto' }}>
                            <Tooltip title="Delete message template">
                                <DeleteIcon />
                            </Tooltip>
                        </IconButton>
                    )}
                    <IconButton aria-label="cancel" onClick={handleCancelClick}>
                        <Tooltip title="Cancel">
                            <CancelIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        aria-label="Save"
                        onClick={handleSaveClick}
                        disabled={createSmsTemplateLoading || createQuickReplyTemplateLoading || createWhatsappTemplateLoading || updateTemplateLoading}
                    >
                        <Tooltip title="Save">
                            <SaveIcon />
                        </Tooltip>
                    </IconButton>
                </DialogActions>
                <ConfirmDialog title="Delete Message Template" open={confirmDeletionOfTemplate} setOpen={() => setConfirmDeletionOfTemplate(false)} onConfirm={handleDeleteTemplateConfirmed}>
                    <Typography variant="body1">Are you sure you wish to delete this template?</Typography>
                </ConfirmDialog>
            </Dialog>
            <Dialog
                open={whatsappTemplateSubmittedDialogVisible}
                onClose={handleWhatsappTemplateDialogClose}
                aria-labelledby="confirm-dialog"
            >
                <div>
                    <DialogTitle id="confirm-dialog">WhatsApp template submitted</DialogTitle>
                    <DialogContent>
                        <Typography variant="h5">
                            WhatsApp Template has been submitted for approval. When approved it will appear in the list of templates.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={handleWhatsappTemplateDialogClose}
                            color="secondary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

MessageTemplateDialog.propTypes = {
    channelType: PropTypes.oneOf(['SMS', 'QuickReply', 'Whatsapp']),
    templateContent: PropTypes.string,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    editedTemplate: PropTypes.object,
    handleClose: PropTypes.func.isRequired
};

export default MessageTemplateDialog;