import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/messageTemplateActions';

const initialState = {
    messageTemplates: [],
    quickReplyTemplates: [],
    whatsappTemplates: [],
    messageTemplatesLoading: true,
    refreshTemplates: false
};

const messageTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_MESSAGE_TEMPLATES: {
            const messageTemplates = action?.data
                ?.filter?.((tmpl) => tmpl?.state !== 'Disabled')
                ?.map((tmpl) => ({
                    ...tmpl,
                    content: tmpl?.extensionParameters?.rawContent ?? tmpl?.extensionParameters?.content ?? '',
                    buttons: tmpl?.extensionParameters?.Buttons ?? [],
                    category: tmpl?.extensionParameters?.category ?? '',
                    identityNumber: tmpl?.extensionParameters?.identityNumber ?? ''
                })) ?? [];
            return {
                ...state,
                messageTemplates,
                quickReplyTemplates: messageTemplates?.filter?.((tmpl) => tmpl?.type === 'QuickReply') ?? [],
                whatsappTemplates: messageTemplates?.filter?.((tmpl) => tmpl?.type === 'InstantMessage' && tmpl?.extensionParameters?.protocol === 'Whatsapp') ?? [],
                messageTemplatesLoading: false,
                refreshTemplates: false
            };
        }
        case ACTIONS.SET_MESSAGE_TEMPLATES_LOADING: {
            return {
                ...state,
                messageTemplatesLoading: true
            };
        }
        case ACTIONS.REFRESH_TEMPLATES: {
            return {
                ...state,
                refreshTemplates: true
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default messageTemplateReducer;
