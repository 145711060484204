import { size as CarrierBillingStatisticsBarWidget } from 'src/widgets/CarrierBillingStatisticsBarWidget';
import { size as CarrierBillingStatisticsDoughnutWidget } from 'src/widgets/CarrierBillingStatisticsDoughnutWidget';
import { size as CodeRedeemedCodesMapWidget } from 'src/widgets/CodeRedeemedCodesMapWidget';
import { size as CodeStatisticsBarWidget } from 'src/widgets/CodeStatisticsBarWidget';
import { size as CodeStatisticsDoughnutWidget } from 'src/widgets/CodeStatisticsDoughnutWidget';
import { size as DialogChatStatisticsWidget } from 'src/widgets/DialogChatStatisticsWidget';
import { size as DialogChatWindowWidget } from 'src/widgets/DialogChatWindowWidget';
import { size as DialogMessageStatisticsWidget } from 'src/widgets/DialogMessageStatisticsWidget';
import { size as GroupMessageStatusWidget } from 'src/widgets/GroupMessageStatusWidget';
import { size as KayakLookupWidget } from 'src/widgets/KayakLookupWidget';
import { size as MessageStatisticsBarWidget } from 'src/widgets/MessageStatisticsBarWidget';
import { size as MessageStatisticsDoughnutWidget } from 'src/widgets/MessageStatisticsDoughnutWidget';
import { size as MessageStatisticsOperatorDoughnutWidget } from 'src/widgets/MessageStatisticsOperatorDoughnutWidget';
import { size as MessageStatisticsReportingBarWidget } from 'src/widgets/MessageStatisticsReportingBarWidget';
import { size as GuideWidget } from 'src/widgets/GuideWidget';
import { size as SmartAgentWidget } from 'src/widgets/SmartAgentWidget';
import { size as SummaryWidget } from 'src/widgets/SummaryWidget';
import { size as TwoWayMessageStatisticsBarWidget } from 'src/widgets/TwoWayMessageStatisticsBarWidget';
import { size as TwoWayMessagingEventsWidget } from 'src/widgets/TwoWayMessagingEventsWidget';
import { size as FavouriteServiceWidget } from 'src/widgets/FavouriteServiceWidget';
import { size as UrlShortenerWidget } from 'src/widgets/UrlShortenerWidget';
import { size as TwoWayPollStatisticsBarWidget } from 'src/widgets/TwoWayPollStatisticsBarWidget';

export const sizes = {
    CarrierBillingStatisticsBarWidget,
    CarrierBillingStatisticsDoughnutWidget,
    CodeRedeemedCodesMapWidget,
    CodeStatisticsBarWidget,
    CodeStatisticsDoughnutWidget,
    DialogChatStatisticsWidget,
    DialogChatWindowWidget,
    DialogMessageStatisticsWidget,
    GroupMessageStatusWidget,
    KayakLookupWidget,
    MessageStatisticsBarWidget,
    MessageStatisticsDoughnutWidget,
    MessageStatisticsOperatorDoughnutWidget,
    MessageStatisticsReportingBarWidget,
    GuideWidget,
    SmartAgentWidget,
    SummaryWidget,
    TwoWayMessageStatisticsBarWidget,
    TwoWayMessagingEventsWidget,
    FavouriteServiceWidget,
    UrlShortenerWidget,
    TwoWayPollStatisticsBarWidget
};