import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import rkioskiIcon from 'src/assets/rkioski-logo.png';

const GoogleMap = ({ codes, allowZoomOnScroll }) => {
    const redeemedCodeEvents = React.useMemo(() => {
        const redeemedCodeEvents = codes?.map((c) => {
            return c?.events?.find((e) => e?.type === 'Redeemed');
        });
        return redeemedCodeEvents.filter((e) => e !== undefined);
    }, [codes]);

    let prevInfoWindow = null;

    const handleApiLoaded = (map, maps) => {
        const markers = redeemedCodeEvents?.map((event) => {
            // if there is no coordinates, don´t try to create any marker
            if (!event?.locationLatitude || !event?.locationLongitude) {
                return null;
            }

            const amountRedeemed = redeemedCodeEvents?.filter((e) => e?.locationName === event?.locationName).length;

            map.addListener('zoom_changed', () => {
                if (prevInfoWindow) {
                    prevInfoWindow.close();
                }
            });

            const icon = {
                url: rkioskiIcon,
                scaledSize: new maps.Size(50, 50)
            };

            const marker = new maps.Marker({
                position: {
                    lat: parseFloat(event?.locationLatitude),
                    lng: parseFloat(event?.locationLongitude)
                },
                title: `${event?.locationName ?? ''}\n${amountRedeemed} ${amountRedeemed === 1 ? 'code redeemed' : 'codes redeemed'}`,
                icon
            });

            const infoContent = `<div style='padding: 5px;'>
            <h3 style='color: #666; margin-bottom: 7px;'>${event?.locationName ?? ''}</h2>
            <h4 style='color: #666;'>${amountRedeemed} ${amountRedeemed === 1 ? 'code redeemed' : 'codes redeemed'}</h3>
            </div>`;

            const infowindow = new maps.InfoWindow({
                content: infoContent
            });

            marker.addListener('click', () => {
                if (prevInfoWindow) {
                    prevInfoWindow.close();
                }
                prevInfoWindow = infowindow;
                infowindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: true
                });
            });

            return marker;
        });

        return new MarkerClusterer({ markers: markers.filter((m) => m !== null), map });
    };

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_APIKEY }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
                handleApiLoaded(map, maps);
            }}
            defaultCenter={{ lat: 62.80599712285822, lng: 25.045265800687517 }}
            defaultZoom={6}
            options={{ streetViewControl: true, maxZoom: 17, scrollwheel: allowZoomOnScroll ?? true }}
        />
    );
};

GoogleMap.propTypes = {
    codes: PropTypes.array.isRequired,
    allowZoomOnScroll: PropTypes.bool.isRequired
};

export default GoogleMap;