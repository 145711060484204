import React from 'react';
import { sum } from 'src/utils/arrayFunctions';
import uuid from 'uuid';
import moment from 'moment';
import { useHttpGetRequest } from 'src/utils/httpClient';
import { useTheme } from '@material-ui/styles';

const validIntervals = ['day', 'week', 'month', 'quarter', 'year'];

export const useOutboundMessageDoughnutData = (statistics, visualization, interval) => {
    const theme = useTheme();
    const [data, setData] = React.useState([]);

    const filteredData = React.useMemo(() => {
        if (interval && validIntervals.includes(interval)) {
            const stats = statistics?.filter((s) => moment(s?.groupingStart).isBetween(moment().startOf(interval), moment())) ?? [];
            return stats;
        }

        return statistics;
    }, [statistics, interval]);

    React.useEffect(() => {
        let mounted = true;

        const _delivered = sum(filteredData)((s) => s?.deliveryStatusCounts?.delivered ?? 0);
        const _pending = sum(filteredData)((s) => s?.deliveryStatusCounts?.pending ?? 0);
        const _failed = sum(filteredData)((s) => s?.deliveryStatusCounts?.failed ?? 0);
        const total = sum(filteredData)((s) => s?.deliveryStatusCounts?.all ?? 0);

        let _data = [
            {
                id: uuid(),
                label: 'Delivered',
                value: _delivered,
                color: theme.palette.status.delivered.main
            },
            {
                id: uuid(),
                label: 'Pending',
                value: _pending,
                color: theme.palette.status.pending.main
            },
            {
                id: uuid(),
                label: 'Failed',
                value: _failed,
                color: theme.palette.status.failed.main
            }
        ];

        if (visualization === 'percentage') {
            _data = _data.map((d) => ({ ...d, value: (d.value / total) * 100 }));
        }

        if (mounted) {
            setData(_data);
        }

        return () => { mounted = false; };
    }, [filteredData, visualization, interval, theme]);

    return data;
};

export const useOutboundMessageBarData = (statistics, statusFilter, groupBy, visualization) => {
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        let mounted = true;

        const resp = statistics?.reduce((acc, current) => {
            const _key = moment(current?.groupingStart).startOf(groupBy).toJSON();

            const deliveredAmount = current?.deliveryStatusCounts?.delivered ?? 0;
            const pendingAmount = current?.deliveryStatusCounts?.pending ?? 0;
            const failedAmount = current?.deliveryStatusCounts?.failed ?? 0;
            const totalAmount = current?.deliveryStatusCounts?.all ?? 0;

            return {
                ...acc,
                [_key]: {
                    Delivered: (acc?.[_key]?.Delivered ?? 0) + deliveredAmount,
                    Pending: (acc?.[_key]?.Pending ?? 0) + pendingAmount,
                    Failed: (acc?.[_key]?.Failed ?? 0) + failedAmount,
                    Total: (acc?.[_key]?.Total ?? 0) + totalAmount
                }
            };
        }, {});

        const getProperty = (propName) => {
            const formatData = (data, total) => {
                switch (visualization) {
                    case 'percentage': {
                        return (data / total) * 100;
                    }
                    default: {
                        return data;
                    }
                }
            };

            return Object.keys(resp ?? {}).map((key) => ({ x: key, y: formatData(resp?.[key]?.[propName], resp?.[key]?.Total) }));
        };

        if (mounted) {
            switch (statusFilter) {
                case 'delivered':
                    setData({
                        Delivered: getProperty('Delivered')
                    });
                    break;
                case 'pending':
                    setData({
                        Pending: getProperty('Pending')
                    });
                    break;
                case 'failed':
                    setData({
                        Failed: getProperty('Failed')
                    });
                    break;
                default:
                    setData({
                        Delivered: getProperty('Delivered'),
                        Pending: getProperty('Pending'),
                        Failed: getProperty('Failed')
                    });
            }
        }
        return () => { mounted = false; };
    }, [statistics, statusFilter, groupBy, visualization]);

    return data;
};

export const useOperatorDoughnutData = (data, visualization) => {
    const theme = useTheme();
    const [resp, setResp] = React.useState([]);

    const { payload: mobileNetworkCodes } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messagepersistence/statistics/operators/mobile-network-codes'
    });

    const { payload: operatorCodes } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messagepersistence/statistics/operators/operator-codes'
    });

    React.useEffect(() => {
        let mounted = true;

        const getOperatorName = (operatorCode) => {
            let foundOperator;
            // Check if Mobile network code and only finnish country code
            if (operatorCode?.length > 2 && operatorCode?.startsWith('244')) {
                const _opCode = operatorCode.replace('244', '');
                foundOperator = mobileNetworkCodes?.find((mnc) => mnc?.Number === _opCode)?.Company;
            }
            foundOperator = operatorCodes?.find((opc) => opc?.Number === operatorCode)?.Company;

            switch (foundOperator) {
                case 'Elisa Oyj':
                    return 'Elisa';
                case 'Telia Finland Oyj':
                    return 'Telia';
                case 'DNA Oyj':
                    return 'DNA';
                default:
                    return 'Other';
            }
        };

        if (data && operatorCodes && mobileNetworkCodes) {
            let _data = data
                ?.reduce((prev, current) => {
                    const operatorName = getOperatorName(current.operatorData);

                    if (prev[operatorName]) {
                        return {
                            ...prev,
                            [operatorName]: prev[operatorName] + current.messageCount
                        };
                    }
                    return {
                        ...prev,
                        [operatorName]: current.messageCount
                    };
                }, {}) ?? {};

            _data = Object.keys(_data).map((key, index) => ({
                id: key,
                label: key,
                value: _data[key],
                color: theme.palette.doughnutCategoryColors?.[index] ?? '#505050'
            })).sort((a, b) => b.value - a.value);

            if (visualization === 'percentage') {
                const total = _data.reduce((a, b) => a + b.value, 0);
                _data = _data.map((d) => ({ ...d, value: (d.value / total) * 100 }));
            }

            if (mounted) {
                setResp(_data);
            }
        }
        return () => { mounted = false; };
    }, [data, visualization, operatorCodes, mobileNetworkCodes, theme]);

    return resp;
};

export const useReportingStatisticsBarData = (statistics, groupBy, visualization) => {
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        let mounted = true;

        const resp = statistics?.reduce((acc, current) => {
            const _key = moment(current?.groupingStart).startOf(groupBy).toJSON();

            const billableMessageAmount = current?.billableMessages ?? 0;

            return {
                ...acc,
                [_key]: {
                    billableMessages: (acc?.[_key]?.billableMessages ?? 0) + billableMessageAmount
                }
            };
        }, {});

        const getProperty = (propName) => {
            const formatData = (data, total) => {
                switch (visualization) {
                    case 'percentage': {
                        return (data / total) * 100;
                    }
                    default: {
                        return data;
                    }
                }
            };

            return Object.keys(resp ?? {}).map((key) => ({ x: key, y: formatData(resp?.[key]?.[propName], resp?.[key]?.Total) }));
        };

        if (mounted) {
            setData({
                billableMessages: getProperty('billableMessages')
            });
        }
        return () => { mounted = false; };
    }, [statistics, groupBy, visualization]);

    return data;
};