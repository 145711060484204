import React from 'react';
import CodeStatisticsBar from 'src/components/Code/Statistics/CodeStatisticsBar';
import PropTypes from 'prop-types';
import WidgetDataProvider from 'src/components/Widgets/WidgetDataProvider';
import { getIntervalDates } from 'src/widgets/widgetUtils';

const CodeStatisticsBarWidget = ({ settings }) => {
    const { chips, showBy, interval, groupBy, statusFilter } = settings;
    const { labels } = getIntervalDates(interval);

    return (
        <WidgetDataProvider settings={{
            ...settings,
            requestSettings: {
                ...settings.requestSettings,
                params: {
                    ...settings.requestSettings.params
                }
            }
        }}
        >
            {(data) => (
                <CodeStatisticsBar
                    data={data ?? []}
                    chips={chips?.concat(labels) ?? []}
                    showBy={showBy}
                    groupBy={groupBy}
                    statusFilter={statusFilter}
                    interval={interval}
                />
            )}
        </WidgetDataProvider>
    );
};

CodeStatisticsBarWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 5,
        h: 3
    },
    lg: {
        w: 6,
        h: 3
    },
    md: {
        w: 6,
        h: 3
    },
    xs: {
        w: 3,
        h: 3
    },
    xxs: {
        w: 8,
        h: 3
    }
};

export default CodeStatisticsBarWidget;