import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { useHttpGetRequest, useHttpRequest } from 'src/utils/httpClient';
import TwoWayPollStatisticsBar from 'src/components/TwoWayMessaging/TwoWayServiceStatistics/TwoWayPollStatistics';
import { useTwoWayPollStatistics } from 'src/components/TwoWayMessaging/TwoWayServiceStatistics/hooks';
import { getIntervalDates } from 'src/widgets/widgetUtils';
import convert from 'xml-js';
import moment from 'moment';

const _ivrMetaDataKey = 'IVR-QS';
const _ivrCandidatePrefix = 'IVR-CANDIDATE-PREFIX';

const TwoWayPollStatisticsBarWidget = ({ settings }) => {
    const { serviceId, chips, visualization, interval, channel } = settings;
    const { labels } = getIntervalDates(interval);
    const [serviceKeywords, setServiceKeywords] = React.useState([]);
    const [twoWayService, setTwoWayService] = React.useState(null);
    const [messageStatistics, setMessageStatistics] = React.useState([]);
    const [ivrStatistics, setIvrStatistics] = React.useState([]);
    const [servicesFetchedOnMount, setServicesFetchedOnMount] = React.useState(false);
    const [resultsFetchedOnMount, setResultsFetchedOnMount] = React.useState(false);
    const [ivrResultsFetchedOnMount, setIvrResultsFetchedOnMount] = React.useState(false);
    const [fetchInterval, setFetchInterval] = React.useState({
        definedInterval: 'thisweek',
        intervalDescr: 'This week',
        from: moment().startOf('week'),
        to: moment()
    });

    const pollStatistics = useTwoWayPollStatistics(serviceKeywords, messageStatistics, ivrStatistics);

    const { query, loading: servicesLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/twoway'
    });

    const fetchTwoWayServices = React.useCallback(query, []);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload } = await fetchTwoWayServices();
            setTwoWayService(payload?.find?.((s) => s.id === serviceId) ?? null);
        };

        if (!servicesFetchedOnMount) {
            setServicesFetchedOnMount(true);
            makeRequest();
        }
    }, [fetchTwoWayServices, servicesFetchedOnMount]);

    const { payload: serviceKeywordsPayload, loading: serviceKeywordsLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/twoway/inputsources/servicekeywords/${serviceId}`
    });

    React.useEffect(() => {
        let mounted = true;

        if (serviceKeywordsPayload && mounted) {
            setServiceKeywords(serviceKeywordsPayload ?? []);
        }

        return () => { mounted = false; };
    }, [serviceKeywordsPayload]);

    // get ivr results
    const { mutate: getIvrResults, loading: ivrResultsLoading } = useHttpRequest((querystring) => ({
        method: 'GET',
        endpoint: `/messaging/services/twoway/ivrfeed?${querystring}`
    }));

    React.useEffect(() => {
        let ivrRemapped = [];
        const ivrQuerystring = twoWayService?.serviceMetadata?.find?.((m) => m.key === _ivrMetaDataKey)?.value ?? '';
        const ivrCandidatePrefix = twoWayService?.serviceMetadata?.find?.((m) => m.key === _ivrCandidatePrefix)?.value ?? '';

        const makeRequest = async () => {
            const { payload, error } = await getIvrResults(ivrQuerystring);

            if (!error) {
                const options = { compact: true };
                const ivrData = convert.xml2js(payload, options);
                ivrRemapped = ivrData?.voting?.candidates?.candidate?.map((c) => (
                    {
                        candidate: `${ivrCandidatePrefix}${c?._attributes?.name}`,
                        ivr: parseInt(c?._attributes?.votes, 10)
                    }
                ));
                setIvrStatistics(ivrRemapped);
            }
        };

        if (ivrQuerystring !== '' && !ivrResultsFetchedOnMount) {
            setIvrResultsFetchedOnMount(true);
            makeRequest();
        }
    }, [twoWayService, ivrResultsFetchedOnMount]);

    const { query: pollQuery, loading: pollStatisticsLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messagepersistence/statistics/twoway/${serviceId}/poll`,
        params: {
            from: fetchInterval?.from?.valueOf(),
            to: fetchInterval?.to?.valueOf()
        }
    }, false);

    const fetchPollStatistics = React.useCallback(pollQuery, []);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload } = await fetchPollStatistics();
            setMessageStatistics(payload ?? []);
        };

        if (!resultsFetchedOnMount && moment(twoWayService?.startDate) < moment()) {
            setResultsFetchedOnMount(true);
            makeRequest();
        }
    }, [twoWayService, resultsFetchedOnMount]);

    const handleIntervalChange = () => {
        const definedInterval = interval?.definedInterval;
        let from = moment();
        let to = moment();
        let intervalDescr = '';

        if (definedInterval === 'lastmonth') {
            from = from.subtract(1, 'months').startOf('month');
            to = to.subtract(1, 'months').endOf('month');
            intervalDescr = 'Last month';
        } else if (definedInterval === 'lastweek') {
            from = from.subtract(1, 'weeks').startOf('weeks');
            to = to.subtract(1, 'weeks').endOf('weeks');
            intervalDescr = 'Last week';
        } else if (definedInterval === 'lasthour') {
            from = from.subtract(1, 'hours');
            intervalDescr = 'Last hour';
        } else if (definedInterval === 'today') {
            from = from.startOf('day');
            intervalDescr = 'Today';
        } else if (definedInterval === 'thisweek') {
            from = from.startOf('week');
            intervalDescr = 'This week';
        } else if (definedInterval === 'thismonth') {
            from = from.startOf('month');
            intervalDescr = 'This month';
        } else if (definedInterval === 'thisquarter') {
            from = from.startOf('quarter');
            intervalDescr = 'This quarter';
        } else if (definedInterval === 'thisyear' || definedInterval === 'all') {
            from = from.startOf('year');
            intervalDescr = 'This year';
        } else if (definedInterval === 'custom') {
            from = moment(interval?.from);
            to = moment(interval?.to);
            intervalDescr = 'Custom';
        }

        setFetchInterval({ ...fetchInterval, definedInterval, intervalDescr, from, to });
    };

    React.useEffect(() => {
        handleIntervalChange();
    }, [settings]);

    const getServiceName = () => {
        return [`Service name: ${twoWayService?.name ?? '2-Way Service'}`];
    };

    if (servicesLoading || serviceKeywordsLoading || ivrResultsLoading || pollStatisticsLoading) {
        return <LinearProgress />;
    }

    return (
        <Suspense fallback={<LinearProgress />}>
            <TwoWayPollStatisticsBar
                data={pollStatistics ?? []}
                chips={getServiceName()?.concat(chips)?.concat([`Interval: ${interval?.intervalDescr}`])?.concat(labels) ?? []}
                showBy={visualization}
                channel={channel}
                hasIvr={!!twoWayService?.serviceMetadata?.find?.((m) => m.key === _ivrMetaDataKey)}
                isWidget
                serviceId={serviceId}
            />
        </Suspense>
    );
};

TwoWayPollStatisticsBarWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 5,
        h: 3
    },
    lg: {
        w: 6,
        h: 3
    },
    md: {
        w: 6,
        h: 3
    },
    xs: {
        w: 3,
        h: 3
    },
    xxs: {
        w: 8,
        h: 3
    }
};

export default TwoWayPollStatisticsBarWidget;