import moment from 'moment';

export const getIntervalDates = (interval) => {
    const { definedInterval, from: _intervalFrom } = interval;
    const intervalFrom = moment(_intervalFrom);
    let from = moment();
    let to = moment();
    const labels = [];

    if (definedInterval === 'lasthour') {
        from = from.subtract(1, 'hours');
        labels.push(`Time: ${from?.format('HH:mm')} - ${to?.format('HH:mm')}`);
    } else if (definedInterval === 'today') {
        from = from.startOf('day');
        labels.push(`Time: ${from?.format('HH:mm')} - ${to?.format('HH:mm')}`);
    } else if (definedInterval === 'thisweek') {
        from = from.startOf('week');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval === 'thismonth') {
        from = from.startOf('month');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval === 'thisquarter') {
        from = from.startOf('quarter');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval === 'thisyear' || definedInterval === 'all') {
        from = from.startOf('year');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval === 'thisMonth') {
        from = from.startOf('month');
        to = to.endOf('month');
        labels.push('Time: This month');
    } else if (definedInterval === 'lastMonth') {
        from = from.subtract(1, 'month').startOf('month');
        to = to.subtract(1, 'month').endOf('month');
        labels.push('Time: Last month');
    } else if (definedInterval === 'thisYear') {
        from = from.startOf('year');
        to = to.endOf('year');
        labels.push('Time: This year');
    } else if (definedInterval === 'customYear') {
        from = moment(intervalFrom).startOf('year');
        to = moment(intervalFrom).endOf('year');
        labels.push(`Year: ${intervalFrom?.format('YYYY')}`);
    } else if (definedInterval === 'customMonth') {
        from = moment(intervalFrom).startOf('month');
        to = moment(intervalFrom).endOf('month');
        labels.push(`Month: ${intervalFrom?.format('MMM-YYYY')}`);
    } else if (definedInterval === 'custom') {
        from = moment(interval.from);
        to = moment(interval.to);

        labels.push(`Time: ${from?.format('DD.MM.YYYY HH:mm')} - ${to?.format('DD.MM.YYYY HH:mm')}`);
    }
    return { from, to, labels };
};