import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'src/components/Snackbar';
import { Chip, Tooltip } from '@material-ui/core';
import { useHttpRequest } from 'src/utils/httpClient';
import LookupIcon from '@material-ui/icons/VisibilityRounded';
import SearchResults from 'src/components/SmartAgent/SearchResults';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { SMARTAGENTSERVICE } from 'src/smartDialogProducts';
import { useThreadChatWindowContext } from 'src/components/Dialogs/ChatWindow/ThreadChatWindow/ThreadChatWindowContext';

const useStyles = makeStyles((theme) => ({
    smartAgentChip: {
        marginRight: theme.spacing(1)
    }
}));

const SmartAgentButton = ({ protocol, recipientAddress }) => {
    const classes = useStyles();
    const { error: errorSnack } = useSnackbar();
    const smartAgentAssigned = useCheckProductAssignment(SMARTAGENTSERVICE);
    const [lookupResultsOpen, setLookupResultsOpen] = React.useState(false);
    const [searchResults, setSearchresults] = React.useState([]);
    const { isWidget } = useThreadChatWindowContext();

    const handleResultsClose = () => {
        setLookupResultsOpen(false);
    };

    // SmartAgent. lookup
    const { mutate: _smartAgentRequest } = useHttpRequest((term) => ({
        method: 'GET',
        endpoint: '/smartagent/contacts',
        params: {
            term
        }
    }));

    const smartAgentRequest = React.useCallback(_smartAgentRequest, []);

    const handleSmartAgentLookup = async (event, term) => {
        event.stopPropagation();

        const { payload, error, errorMessages } = await smartAgentRequest(term);

        if (!error) {
            if (Array.isArray(payload) && payload.length) {
                setLookupResultsOpen(true);
                setSearchresults(payload);
            } else {
                errorSnack('No contact details found.');
            }
        } else {
            errorSnack(errorMessages);
        }
    };

    if (
        !smartAgentAssigned ||
        isWidget ||
        (protocol !== 'SMS' && protocol !== 'Whatsapp') ||
        !recipientAddress?.startsWith('358')
    ) {
        return null;
    }

    return (
        <>
            <Tooltip title="SmartAgent. lookup">
                <Chip className={classes.smartAgentChip} size="small" icon={<LookupIcon />} label="SmartAgent." onClick={(event) => handleSmartAgentLookup(event, recipientAddress)} />
            </Tooltip>
            <SearchResults open={lookupResultsOpen} setOpen={handleResultsClose} searchResults={searchResults ?? []} />
        </>
    );
};

SmartAgentButton.propTypes = {
    protocol: PropTypes.string,
    recipientAddress: PropTypes.string
};

export default SmartAgentButton;