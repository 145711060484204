import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useHttpGetRequest } from 'src/utils/httpClient';
import { Grid, LinearProgress } from '@material-ui/core';
import DoughnutChart from 'src/components/Charts/DoughnutChart';
import { theme } from 'src/theme';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import SaveAsWidgetOverlay from 'src/components/Widgets/SaveAsWidgetOverlay';
import { useSelector } from 'react-redux';

const doughnutDataModel = (data) => ({
    datasets: [
        {
            data: [data?.Active ?? 0, data?.Read ?? 0, data?.Unread ?? 0, data?.Expired ?? 0],
            backgroundColor: [
                theme.palette.status.dialog.active.main,
                theme.palette.status.dialog.read.main,
                theme.palette.status.dialog.unread.main,
                theme.palette.status.dialog.expired.main,
            ],
            borderWidth: 1,
            borderColor: theme.palette.common.white,
            hoverBorderColor: theme.palette.common.white
        }
    ],
    labels: ['Active', 'Read', 'Unread', 'Expired']
});

export const UserStatsDoughnut = ({ data, chips }) => {
    return <DoughnutChart data={doughnutDataModel(data)} chips={chips} />;
};

UserStatsDoughnut.propTypes = {
    data: PropTypes.object.isRequired,
    chips: PropTypes.arrayOf(PropTypes.string).isRequired
};

const useStyles = makeStyles(() => ({
    gridContainer: {
        flex: 1
    }
}));

const DialogChatStatisticsChart = ({ requestOptions: { from, to, user }, interval }) => {
    const classes = useStyles();
    const { serviceId } = useParams();
    const requestSettings = {
        method: 'GET',
        endpoint: '/messaging/services/dialog/statistics/threads',
        params: {
            sourceServiceId: serviceId,
            from,
            to,
            ...user !== 'all' ? { owner: user } : {}
        }
    };
    const { payload, loading } = useHttpGetRequest(requestSettings);

    const { dialogServicesById } = useSelector((state) => state.dialog);
    const serviceName = dialogServicesById?.[serviceId]?.name ?? 'Dialog Service';

    const data = Array.isArray(payload) ? payload : [];

    return (
        <Suspense fallback={<LinearProgress />}>
            <LoadingOverlay loading={loading}>
                <Grid className={classes.gridContainer} container spacing={3} justifyContent="space-evenly">
                    {data.map((group) => (
                        <Grid key={group?.owner ?? ''} item xs={12} sm={6} lg={4}>
                            <SaveAsWidgetOverlay
                                component="DialogChatStatisticsWidget"
                                settings={{
                                    title: 'Dialog Chat Statistics',
                                    interval,
                                    requestSettings: {
                                        ...requestSettings,
                                        params: {
                                            ...requestSettings.params,
                                            owner: group?.owner ?? ''
                                        }

                                    },
                                    chips: [
                                        group?.owner ?? '',
                                        `Service: ${serviceName}`
                                    ]
                                }}
                            >
                                <UserStatsDoughnut data={group?.statuses} chips={[group?.owner ?? '']} />
                            </SaveAsWidgetOverlay>
                        </Grid>
                    ))}
                </Grid>
            </LoadingOverlay>
        </Suspense>
    );
};

DialogChatStatisticsChart.propTypes = {
    requestOptions: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
        user: PropTypes.string.isRequired
    }).isRequired,
    interval: PropTypes.shape({
        definedInterval: PropTypes.string.isRequired,
        from: PropTypes.object.isRequired,
        to: PropTypes.object.isRequired
    })
};

export default DialogChatStatisticsChart;