import React from 'react';
import PropTypes from 'prop-types';
import WidgetDataProvider from 'src/components/Widgets/WidgetDataProvider';
import { getIntervalDates } from 'src/widgets/widgetUtils';
import { UserStatsDoughnut } from 'src/components/Dialogs/DialogServiceStatistics/DialogChatStatisticsChart';

const DialogChatStatisticsWidget = ({ settings }) => {
    const { chips, interval } = settings;
    const { from, to, labels } = getIntervalDates(interval);

    return (
        <WidgetDataProvider settings={{
            ...settings,
            requestSettings: {
                ...settings.requestSettings,
                params: {
                    ...settings.requestSettings.params,
                    from: from.valueOf(),
                    to: to.valueOf()
                }
            }
        }}
        >
            {(data) => (
                <UserStatsDoughnut data={data?.[0]?.statuses ?? {}} chips={chips?.concat(labels) ?? []} />
            )}
        </WidgetDataProvider>
    );
};

DialogChatStatisticsWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 3,
        h: 3
    },
    lg: {
        w: 4,
        h: 3
    },
    md: {
        w: 3,
        h: 3
    },
    xs: {
        w: 3,
        h: 3
    },
    xxs: {
        w: 4,
        h: 3
    }
};

export default DialogChatStatisticsWidget;