import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { useTheme, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    chartContainer: {
        maxHeight: 250,
        height: '100%',
        width: '100%',
        position: 'relative'
    }
}));

const DoughnutChart = ({ data: dataProp }) => {
    const classes = useStyles();
    const theme = useTheme();

    const data = {
        datasets: [
            {
                data: dataProp?.map((d) => d?.value) ?? [],
                backgroundColor: dataProp?.map((d) => d?.color) ?? [],
                borderWidth: 1,
                borderColor: theme.palette.common.white,
                hoverBorderColor: theme.palette.common.white
            }
        ],
        labels: dataProp?.map((d) => d?.label) ?? []
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 75,
        animation: {
            animateScale: true,
            animateRotate: true
        },
        legend: {
            display: false
        },
        layout: {
            padding: 0
        },
        tooltips: {
            enabled: false
        }
    };

    return (
        <div className={classes.chartContainer}>
            <Doughnut
                data={data}
                options={options}
            />
        </div>
    );
};

DoughnutChart.propTypes = {
    data: PropTypes.array.isRequired
};

export default DoughnutChart;