import React from 'react';
import OneWayStatisticsDoughnut from 'src/components/OneWayMessaging/OneWayServiceStatistics/OneWayStatisticsDoughnut';
import PropTypes from 'prop-types';
import WidgetDataProvider from 'src/components/Widgets/WidgetDataProvider';
import { getIntervalDates } from 'src/widgets/widgetUtils';

const MessageStatisticsDoughnutWidget = ({ settings }) => {
    const { chips, visualization, interval } = settings;
    const { from, to, labels } = getIntervalDates(interval);

    return (
        <WidgetDataProvider settings={{
            ...settings,
            requestSettings: {
                ...settings.requestSettings,
                params: {
                    ...settings.requestSettings.params,
                    from: from.valueOf(),
                    to: to.valueOf()
                }
            }
        }}
        >
            {(data) => (
                <OneWayStatisticsDoughnut
                    data={data ?? []}
                    chips={chips?.concat(labels) ?? []}
                    showBy={visualization}
                />
            )}
        </WidgetDataProvider>
    );
};

MessageStatisticsDoughnutWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 3,
        h: 3
    },
    lg: {
        w: 4,
        h: 3
    },
    md: {
        w: 3,
        h: 3
    },
    xs: {
        w: 3,
        h: 3
    },
    xxs: {
        w: 4,
        h: 3
    }
};

export default MessageStatisticsDoughnutWidget;